import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import useSWR from "swr";

import { BaseContext, UserActionTypes, UserContext } from "~context";
import {
  LoginInput,
  MeQuery,
  RegisterInput,
  SocialAuthType,
  TwoFactorAuthenticationCodeInput,
  VerifyEmailInput,
  VerifyMagicLinkMutation,
} from "~graphql/sdk";
import { sdk } from "~lib";
import { getError, handlePromise, showToast } from "~lib/helpers";
import { useReCaptchaSDK } from "./useReCaptcha";

const authUrls = ["/login", "/signup"];

export const getSession = async (_: string, orgId: string) =>
  sdk({ orgId }).me();

export interface LoginParams {
  magicLinkInput?: VerifyEmailInput;
  loginInput?: LoginInput;
}
export const useAccount = () => {
  const router = useRouter();
  const { organization } = useContext(BaseContext);

  const { recaptchaSdkGenerator } = useReCaptchaSDK();
  const {
    user,
    dispatch,
    isLoggedIn,
    hasLoaded,
    isAdmin,
    isPOS,
    isSalesOutlet,
    hasCheckedLogin,
    setHasCheckedLogin,
    isTwoFactorAuthenticated,
    isEventManager,
    userRoles,
    authState,
  } = useContext(UserContext);
  const [isLoggingIn, setLoggingIn] = useState(false);
  const [checkInitialising, setCheckInitialising] = useState<boolean>(
    undefined
  );

  useEffect(() => {
    if (isLoggedIn) {
      setCheckInitialising(!isLoggedIn);
    }
  }, [isLoggedIn]);

  async function _getUserAndLogin(
    {
      trackUserLogin,
    }: {
      trackUserLogin: boolean;
    } = { trackUserLogin: true }
  ) {
    return sdk({ orgId: organization?.id })
      .me()
      .then((data) => {
        if (trackUserLogin) {
          void window?.Converge?.track?.userLogin({
            emailAddress: data?.me?.email,
            userAccountIdentifierValue: data?.me?.id,
            userAccountIdentifierTypeName: undefined,
          });
        }

        void window?.Converge?.identity?.mergeProfile({
          firstName: [data?.me?.firstName],
          lastName: [data?.me?.lastName],
          email: [data?.me?.email],
          phone: [data?.me?.phoneNumber],
          address: [
            {
              addressLine1: data?.me?.billingAddress.line1 ?? null,
              addressLine2: data?.me?.billingAddress.line2 || null,
              postcode: data?.me?.billingAddress?.postalCode || null,
              locality: data?.me?.billingAddress.suburb || null,
              country: data?.me?.billingAddress.country,
            },
          ],
        });

        dispatch({ type: UserActionTypes.LOGIN, payload: data.me });
      })
      .catch(() => {
        dispatch({ type: UserActionTypes.UNAUTHENTICATE_USER });
      });
  }

  function _unauthenticateUser() {
    dispatch({ type: UserActionTypes.UNAUTHENTICATE_USER });
  }

  // On inital load, check if the user is logged in
  // before attempting to query me(). This is to
  // prevent quering me() while not logged in as it causes
  // unauthorized errors.
  useSWR(
    hasCheckedLogin ? null : ["isloggedIn"],
    async () =>
      sdk({ orgId: organization.id })
        .isLoggedIn()
        .then((res) => {
          setHasCheckedLogin(true);
          setCheckInitialising(res.isLoggedIn);

          if (res.isLoggedIn) {
            setTimeout(() => {
              void _getUserAndLogin({ trackUserLogin: false });
            }, 500);
          } else {
            _unauthenticateUser();
          }
          return res;
        })
        .catch((error) => {
          _unauthenticateUser();
        }),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: true,
    }
  );

  useEffect(() => {
    if (
      isLoggedIn &&
      user?.isTwoFactorAuthenticationEnabled &&
      !isTwoFactorAuthenticated &&
      router?.pathname !== "/authenticate"
    ) {
      void router?.push("/authenticate");
    } else if (isLoggedIn && authUrls.includes(router?.pathname)) {
      const url = `/${decodeURIComponent(
        (router.query.redirect as string) ?? ""
      )}`;
      void router?.push(url);
    }
  }, [isLoggedIn, router]);

  const isVerifyEmailInput = (
    input: VerifyEmailInput | LoginInput
  ): input is VerifyEmailInput => !!Object.hasOwnProperty.call(input, "token");

  const isLoginInput = (
    input: VerifyEmailInput | LoginInput
  ): input is LoginInput => !!Object.hasOwnProperty.call(input, "password");

  async function login(input: LoginInput): Promise<boolean>;
  async function login(
    input: VerifyEmailInput
  ): Promise<VerifyMagicLinkMutation>;
  async function login(
    input: LoginInput | VerifyEmailInput
  ): Promise<boolean | VerifyMagicLinkMutation> {
    setLoggingIn(true);

    if (isVerifyEmailInput(input)) {
      const { data, error } = await handlePromise(async () =>
        sdk({ orgId: organization?.id }).verifyMagicLink({ input })
      );

      if (error) {
        showToast(getError(error, "graphQL"), "error");
        return false;
      }

      // void mutate(undefined, true);
      void _getUserAndLogin();
      return data;
    }

    if (isLoginInput(input)) {
      const sdkLoginFn = await recaptchaSdkGenerator("login");
      const { error } = await handlePromise(async () =>
        sdkLoginFn({ orgId: organization?.id }).login({ input })
      );

      if (error) {
        showToast(getError(error, "graphQL"), "error");
        setLoggingIn(false);
        return false;
      }

      // await mutate(undefined, true);
      void _getUserAndLogin();
      setLoggingIn(false);
      return true;
    }

    return false;
  }

  type RouterParams = Parameters<typeof router.replace>;
  async function logout(): Promise<void>;
  async function logout(...replaceWith: RouterParams): Promise<void>;
  async function logout(...replaceWith: RouterParams | []): Promise<void> {
    const { error } = await handlePromise(async () =>
      sdk({ orgId: organization?.id }).logout()
    );

    if (error) {
      return showToast(getError(error, "graphQL"), "error");
    }

    // void mutate(null, false);

    dispatch({ type: UserActionTypes.LOGOUT });

    const defaultRoute: RouterParams = ["/", undefined, undefined];
    void router.replace(...(replaceWith.length ? replaceWith : defaultRoute));

    return showToast("Logged out successfully", "success");
  }

  const logoutAfterDelete = () => {
    // void mutate(null, false);
    dispatch({ type: UserActionTypes.LOGOUT });
    void router.replace("/");
  };

  const register = async (input: RegisterInput) => {
    const sdkRegisterFn = await recaptchaSdkGenerator("register");
    const { error } = await handlePromise(async () =>
      sdkRegisterFn({ orgId: organization?.id }).register({ input })
    );

    if (error) {
      showToast(getError(error, "graphQL"), "error");
      return false;
    }

    return { hasVerifiedEmail: false };
  };

  const authenticateWithTwoFactor = async (
    input: TwoFactorAuthenticationCodeInput
  ): Promise<boolean> => {
    setLoggingIn(true);

    const { error } = await handlePromise(async () =>
      sdk({ orgId: organization?.id }).authenticateWithTwoFactor({ input })
    );

    if (error) {
      showToast(getError(error, "graphQL"), "error");
      return false;
    }

    dispatch({
      type: UserActionTypes.AUTHENTICATE_TWO_FACTOR,
    });

    void _getUserAndLogin();

    showToast("Authenticated successfully", "success");

    // void mutate(undefined, true);
    setLoggingIn(false);

    void router.replace("/");
    return true;
  };

  const handleSocialSuccess = async (
    accessToken: string,
    type?: SocialAuthType
  ): Promise<void> => {
    setLoggingIn(true);

    const { error } = await handlePromise(async () =>
      sdk({ orgId: organization?.id }).socialAuth({
        input: {
          socialAuthType: type || SocialAuthType.Facebook,
          accessToken,
        },
      })
    );

    if (error) {
      setLoggingIn(false);
      showToast(getError(error, "graphQL"), "error");
      return;
    }

    // void mutate(undefined, true);
    void _getUserAndLogin();
    setLoggingIn(false);
  };

  return {
    dispatch,
    user,
    error: undefined,
    isLoggedIn,
    isLoggingIn,
    hasLoaded,
    handleSocialSuccess,
    login,
    register,
    logout,
    isAdmin,
    isPOS,
    logoutAfterDelete,
    revaildateUser: _getUserAndLogin,
    authenticateWithTwoFactor,
    isTwoFactorAuthenticated,
    isValidating: false,
    isSalesOutlet,
    hasCheckedLogin,
    isEventManager,
    userRoles,
    isInitialising: checkInitialising,
    authState,
  };
};
