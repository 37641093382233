import React, { useContext } from "react";
import { Link, Text, system, SystemProps } from "flicket-ui";
import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import styled from "styled-components";

import { BaseContext } from "~context";
import { Icon } from "~components/common/Icon";

interface BottomBarProps extends SystemProps {
  currentPage?: string;
}

const StyledBottomBar = styled(motion.div)<BottomBarProps>`
  display: flex;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;

  background-color: ${(p) => p.theme.colors.white};

  font-size: ${(p) => p.theme.fontSizes[0]};
  color: ${(p) => p.theme.colors.N500};

  box-shadow: ${(p) => p.theme.shadows.reversed};

  transition: bottom 0.5s ease-in-out;

  z-index: ${(p) => p.theme.zIndices.docked};

  ${system};
`;

interface LinkProps extends SystemProps {
  currentPage?: "/" | "/memberships" | "/merchandise" | "/examples/main";
  current: boolean;
}

const StyledLink = styled(Link)<LinkProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 33%;

  padding: 12px 0;

  &:hover {
    opacity: 1;
    color: ${(p) => p.theme.colors.N800};
  }

  ${(p) =>
    p.current &&
    `
    color: ${p.theme.colors.N800};
  `}
`;

const visiblePaths = [
  "/",
  "/merchandise",
  "/memberships",
  "/account",
  "/tickets",
  "/tours/[tourId]",
];

export const BottomBar = ({ currentPage = "/" }: BottomBarProps) => {
  const router = useRouter();
  const { organization } = useContext(BaseContext);

  const activePage = router?.pathname || currentPage;

  return (
    <AnimatePresence>
      {visiblePaths.includes(router?.pathname) && (
        <StyledBottomBar
          initial={{ opacity: 0, y: 100 }}
          exit={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          d={{ _: "flex", md: "none" }}
          // @todo remove this line once merchandise is added again
          justifyContent="space-around"
        >
          <StyledLink to="/" current={activePage === "/"}>
            <Icon icon="event" fontSize={6} mb="1/2" />
            <Text fontSize={0} fontWeight="heavy" variant="uppercase">
              Events
            </Text>
          </StyledLink>
          {organization?.showMemberships ? (
            <StyledLink
              to="/memberships"
              current={activePage === "/memberships"}
            >
              <Icon icon="membership" fontSize={6} mb="1/2" />
              <Text fontSize={0} fontWeight="heavy" variant="uppercase">
                Memberships
              </Text>
            </StyledLink>
          ) : null}
          {organization?.shopify && (
            <StyledLink
              to={organization.shopify.navLinkUrl}
              current={activePage === "/merchandise"}
              target={
                organization.shopify.navLinkUrl.startsWith("http")
                  ? "_blank"
                  : undefined
              }
            >
              <Icon icon="shopping-cart" fontSize={6} mb={1} />
              <Text fontSize={0} fontWeight="heavy" variant="uppercase">
                {organization.shopify.navLinkLabel}
              </Text>
            </StyledLink>
          )}
        </StyledBottomBar>
      )}
    </AnimatePresence>
  );
};
