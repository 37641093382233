import React from "react";
import { Box, SystemProps } from "flicket-ui";
import styled from "styled-components";

type SubLayoutProps = SystemProps & {
  isCentered?: boolean;
};

const SubLayout = styled(Box)<SubLayoutProps>`
  position: relative;

  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 64px;

  width: 100%;
  max-width: 100vw;

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    padding-top: 64px;

    ${(p) =>
      p.isCentered &&
      `
      max-width: 1036px;
      margin: 0 auto;
    `}
  }
`;

export const Sub = ({ children, ...props }) => (
  <SubLayout {...props}>{children}</SubLayout>
);
