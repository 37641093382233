import { MutableRefObject, useEffect, useRef, useState } from "react";

/**
 * Based off https://usehooks-ts.com/react-hook/use-intersection-observer
 *
 * With state remove so that it does not trigger a rerender when interesection changes.
 * Instead, we can use the `onChange` callback to handle the intersection change.
 */
export type UseIntersectionObserverOptions = {
  root?: Element | Document | null;
  rootMargin?: string;
  threshold?: number | number[];
  freezeOnceVisible?: boolean;
  onChange?: (
    isIntersecting: boolean,
    entry: IntersectionObserverEntry
  ) => void;
};

type IntersectionReturn = MutableRefObject<Element | null>;

export function useIntersectionObserver({
  threshold = 0,
  root = null,
  rootMargin = "0%",
  freezeOnceVisible = false,
  onChange,
}: UseIntersectionObserverOptions = {}): IntersectionReturn {
  const ref = useRef<Element | null>(null);

  const callbackRef = useRef<UseIntersectionObserverOptions["onChange"]>();

  callbackRef.current = onChange;

  useEffect(() => {
    // Ensure we have a ref to observe
    if (!ref?.current) return;

    // Ensure the browser supports the Intersection Observer API
    if (!("IntersectionObserver" in window)) return;

    let unobserve: (() => void) | undefined;

    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]): void => {
        const thresholds = Array.isArray(observer.thresholds)
          ? observer.thresholds
          : [observer.thresholds];

        entries.forEach((entry) => {
          const isIntersecting =
            entry.isIntersecting &&
            thresholds.some(
              (threshold) => entry.intersectionRatio >= threshold
            );

          if (callbackRef.current) {
            callbackRef.current(isIntersecting, entry);
          }

          if (isIntersecting && freezeOnceVisible && unobserve) {
            unobserve();
            unobserve = undefined;
          }
        });
      },
      { threshold, root, rootMargin }
    );

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [
    JSON.stringify(threshold),
    root,
    rootMargin,
    freezeOnceVisible,
    ref?.current,
  ]);

  return ref;
}

interface InViewProps extends UseIntersectionObserverOptions {
  offset?: number;
  pageConstraint?: "top" | "bottom" | undefined;
}

export function useIntersectionObserverWithPageConstraint({
  offset = 0,
  pageConstraint,
}: InViewProps) {
  const [inView, setInView] = useState(true);

  const ref = useIntersectionObserver({
    threshold: 1,
    root: document,
    rootMargin: `-${offset}px 0px 0px 0px`,
    onChange(isIntersecting, entry) {
      const offsetTop = entry.target?.getBoundingClientRect().top - offset ?? 0;
      if (!isIntersecting) {
        if (offsetTop < 0) {
          if (!pageConstraint || pageConstraint === "top") {
            setInView(false);
          }
        } else {
          if (!pageConstraint || pageConstraint === "bottom") {
            setInView(false);
          }
        }
      } else {
        setInView(true);
      }
    },
  });

  return { ref, inView };
}
