import { StateCreator, StoreMutatorIdentifier } from "zustand";
import cloneDeep from "lodash/cloneDeep";
import { domainAllowsStateLogging } from "~config";
import { initialState } from "./state";

type Logger = <
  T,
  Mps extends [StoreMutatorIdentifier, unknown][] = [],
  Mcs extends [StoreMutatorIdentifier, unknown][] = []
>(
  f: StateCreator<T, Mps, Mcs>,
  name?: string
) => StateCreator<T, Mps, Mcs>;

type LoggerImpl = <T>(
  f: PopArgument<StateCreator<T, [], []>>,
  name?: string
) => PopArgument<StateCreator<T, [], []>>;

const loggerImpl: LoggerImpl = (f, name) => (set, get, store) => {
  type T = ReturnType<typeof f>;
  const loggedSet: typeof set = (...a) => {
    const storeName = name ? `${name}` : "unamed";

    let initialState;
    let delta;

    if (domainAllowsStateLogging) {
      console.debug(
        `%c ${storeName} `,
        "background: #222; color: #FFC0CB",
        `update`
      );

      initialState = cloneDeep(get());
      delta = cloneDeep(a[0]);
    }

    // Update store with new values
    set(...a);

    if (domainAllowsStateLogging) {
      console.debug({
        storeName,
        initialState,
        newState: get(),
        delta,
      });
    }
  };
  store.setState = loggedSet;

  return f(loggedSet, get, store);
};

type PopArgument<T extends (...a: never[]) => unknown> = T extends (
  ...a: [...infer A, infer _]
) => infer R
  ? (...a: A) => R
  : never;

export const logger = (loggerImpl as unknown) as Logger;
