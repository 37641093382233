/* eslint-disable prefer-const */
import { useState, useEffect } from "react";
import { fbAppId } from "~config";

export const useFacebookSDK = () => {
  const [isFacebookLoaded, setFacebookLoaded] = useState(false);

  useEffect(() => {
    if (window.FB) {
      setFacebookLoaded(true);
      return;
    }

    window.fbAsyncInit = () => {
      window.FB.init({
        version: `v8.0`,
        appId: fbAppId,
        cookie: true,
        xfbml: false,
      });
      setFacebookLoaded(true);
    };

    (function (d, s, id) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  return { isFacebookLoaded };
};
