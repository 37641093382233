import { theme } from "flicket-ui";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";

type Breakpoint = keyof typeof theme["breakpoints"];

export const useIsMobile = (breakpoint: Breakpoint = "md") => {
  const theme = useContext(ThemeContext);

  const checkIfMobile = () =>
    typeof window !== "undefined" &&
    window.innerWidth <
      Number(theme.breakpoints[breakpoint].replace(/\D/g, ""));

  const [isMobile, setIsMobile] = useState(checkIfMobile());

  useEffect(() => {
    setIsMobile(checkIfMobile());

    function handleResizeEvent() {
      setIsMobile(checkIfMobile());
    }

    window.addEventListener("resize", handleResizeEvent);
    return () => window.removeEventListener("resize", handleResizeEvent);
  }, [theme.breakpoints]);

  return isMobile;
};

export const useBreakpoint = useIsMobile;
