import React from 'react';
import { Box, SystemProps } from 'flicket-ui';
import styled from 'styled-components';

const OverlayLayout = styled(Box)<SystemProps>`
  position: relative;

  display: flex;
  flex: 1;
  flex-direction: column;

  width: 100%;
  max-width: 100vw;

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    padding-top: 72px;
  }
`;

export const Overlay = ({ children, ...props }) => (
  <OverlayLayout {...props}>{children}</OverlayLayout>
);
