var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import { BrowserTracing } from "@sentry/tracing";

const SENTRY_DSN: string =
  process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (!SENTRY_DSN) {
  throw new Error("SENTRY_DSN is not set");
}

if (!process.env.SENTRY_ENV) {
  console.warn("SENTRY_ENV is not set. Defaulting to development");
} else if (process.env.SENTRY_ENV !== "production") {
  console.log(`SENTRY_ENV: ${process.env.SENTRY_ENV}`);
}

const traceSampleRate = (() => {
  if (!process.env.SENTRY_ENV || process.env.SENTRY_ENV === "development") {
    return 1;
  }

  return 0.2;
})();

const replaySampleRate = (() => {
  if (!process.env.SENTRY_ENV || process.env.SENTRY_ENV === "development") {
    return 1;
  }

  return 0.2;
})();

const chance = (oneIn: number): boolean => {
  return Math.floor(Math.random() * oneIn) === 0;
};

const maybeSendToSentry = (event: Sentry.Event): Sentry.Event | null => {
  const isClientRequestError = `${
    ((event.tags?.status ?? event.tags?.statusCode) || 0) as number
  }`.startsWith("4");

  if (isClientRequestError) {
    return chance(10) ? event : null;
  }

  // Reduce sampling even more for cryptic 403 issues. These have no status codes
  // except for text inside the error message and are the highest sent event to Sentry.
  // Example of issue: https://sentry.io/organizations/flicket-ltd/issues/3189004560/?project=6314319
  const isGraphQL_403 = event.message?.includes("GraphQL Error (Code: 403)");

  if (isGraphQL_403) {
    event.tags.statusCode = "403";
    return chance(20) ? event : null;
  }

  return event;
};

Sentry.init({
  autoSessionTracking: true,
  dsn: SENTRY_DSN,

  tracesSampleRate: traceSampleRate,
  beforeSend: maybeSendToSentry,
  integrations: [new BrowserTracing()],

  // Use sentry env variable here as we don't use NODE_ENV to indicate staging,
  // whereas we do want the separation between staging/production/development
  // in sentry
  environment: process.env.SENTRY_ENV ?? "development",
  release: process.env.SENTRY_RELEASE ?? "testrelease",

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
