import styled from "styled-components";

export const ImageWrapper = styled.div<{
  width: number;
  height: number;
}>`
  position: relative;
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
`;
