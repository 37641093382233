export const apiUrl = process.env.API_URL || "http://localhost:3001";

const nonProductionDomainWhitelist = [
  "api.staging.flicket.",
  "api.flicket-preview.",
  "localhost",
];

export const domainAllowsSessionFeatureFlags = nonProductionDomainWhitelist.some(
  (domain) => apiUrl.includes(domain)
);

export const domainAllowsStateLogging = nonProductionDomainWhitelist.some(
  (domain) => apiUrl.includes(domain)
);
