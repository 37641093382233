import { ToastAlert } from "flicket-ui";
import { ReactNode } from "react";
import { toast, ToastOptions } from "react-toastify";

export const showToast = (
  msg: ReactNode,
  type?: "success" | "warning" | "error",
  options?: ToastOptions
) => {
  toast(() => <ToastAlert msg={msg} type={type} />, options);
};
