import { Box, Flex, Link, Stack, system, SystemProps } from "flicket-ui";
import React, { FC, useContext } from "react";
import styled from "styled-components";
import { marketingUrl } from "~config";
import { UserContext } from "~context";
import { useOrganization } from "~hooks";
import { pick } from "@styled-system/props";

interface FooterProps extends SystemProps {
  hasFullPageBackground?: boolean;
  narrow?: boolean;
}

const StyledFooter = styled(Box)<{ $narrow: boolean }>`
  justify-content: center;

  width: 100%;

  padding: 32px 32px 64px;

  background-color: ${(p) => p.theme.colors.N100};

  color: ${(p) => p.theme.colors.N600};
  font-size: ${(p) => p.theme.fontSizes[3]};
  font-weight: ${(p) => p.theme.fontWeights.medium};
  line-height: 150%;
  letter-spacing: -0.165px;

  > div {
    display: flex;
    justify-content: space-between;

    position: relative;

    width: 100%;
    max-width: ${(p) => (p.$narrow ? 940 : 1184)}px;

    padding: 24px 0 64px 0;

    &::before {
      content: "";

      position: absolute;
      top: 0;
      left: 0;
      right: 0;

      height: 1px;

      background-color: ${(p) => p.theme.colors.N300};
    }
  }

  ${system}
`;

export const Footer: FC<FooterProps> = ({
  hasFullPageBackground,
  narrow = false,
  ...props
}) => {
  const { user } = useContext(UserContext);
  const { organization } = useOrganization();

  const footerStyles = (() => {
    if (!hasFullPageBackground) return {};

    return {
      background: "transparent",
    };
  })();

  return (
    <StyledFooter
      d={{ _: "none", md: "flex" }}
      flex={1}
      {...footerStyles}
      {...pick(props)}
      $narrow={narrow}
      // Keep the footer at the bottom of the page
      maxH={150}
    >
      <Stack
        direction={{ _: "vertical", md: "horizontal" }}
        flexWrap="nowrap"
        justifyContent="space-between"
        flexShrink={0}
        gap={[1, 1, 1, 8]}
      >
        <Stack
          direction={{ _: "vertical", sm: "horizontal" }}
          flexWrap="nowrap"
          flexShrink={0}
          gap={[1, 1, 1, 8]}
        >
          <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to={user ? "/data-access" : "/login"}>
            Data Access &amp; Deletion
          </Link>
          <a
            target="_blank"
            href={
              organization?.helpLink ?? "https://flicketfaqs.groovehq.com/help"
            }
            rel="noopener noreferrer"
          >
            Help
          </a>
        </Stack>
        <Link href={marketingUrl}>Powered by Flicket</Link>
      </Stack>
    </StyledFooter>
  );
};
