import React, { Component, ErrorInfo } from "react";
import Router from "next/router";
import * as Sentry from "@sentry/nextjs";
import { CurrentOrganizationQuery } from "~graphql/sdk";
import { ErrorBody } from "~pages/_error";

interface ErrorBoundaryProps {
  children: React.ReactNode;
  organization: CurrentOrganizationQuery["currentOrganization"];
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  public constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(
      "Error boundary caught error",
      this.props.organization,
      error,
      errorInfo,
      Router.asPath
    );
    Sentry.captureException(
      {
        error,
        errorInfo,
        path: Router.asPath,
        organisation: this.props.organization,
      },
      {
        tags: {
          runtimeError: true,
        },
      }
    );
  }
  public render() {
    if (this.state.hasError) {
      return <ErrorBody statusCode={undefined} />;
    }
    return this.props.children;
  }
}
