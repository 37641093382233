import React, { ReactNode } from "react";
import { Flex } from "flicket-ui";

type SubHeaderProps = {
  children: ReactNode;
};

export const SubHeader = ({ children }: SubHeaderProps) => (
  <Flex
    d={{ _: "flex", md: "none" }}
    justifyContent="space-between"
    position="relative"
    w="100%"
    pt={2}
    pb={{ _: 0, md: 2 }}
    px={{ _: 0, md: 2 }}
    zIndex={2}
  >
    {children}
  </Flex>
);
