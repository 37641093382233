import React from 'react';

import { Flex, Spinner } from 'flicket-ui';

export const LoadingState = () => {
  return (
    <Flex flex={1} justifyContent="center" alignItems="center">
      <Spinner />
    </Flex>
  );
};
