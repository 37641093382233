import { IntegrationType } from "~graphql/sdk";
import { BuyNowPayLaterConfig } from "./checkout.types";

export const DEBIT_DISPLAY_NAME = "Debit / credit card";

export const LAYBUY_CONFIG: BuyNowPayLaterConfig = {
  terms: 6,
  period: "week",
  icon: "laybuy",
  brandColour: "#786dff",
};

export const AFTERPAY_CONFIG: BuyNowPayLaterConfig = {
  terms: 4,
  period: "fortnight",
  icon: "afterpay",
  brandColour: "#9DDFE8",
};

export const BNPL_CONFIG = {
  [IntegrationType.PaymentLaybuy]: LAYBUY_CONFIG,
  [IntegrationType.PaymentAfterpay]: AFTERPAY_CONFIG,
} as const;

export const PARTIAL_POINT_PAYMENT_TYPES = [
  IntegrationType.PaymentStripe,
  IntegrationType.PaymentPin,
  IntegrationType.PaymentWindcave,
  IntegrationType.PaymentLaybuy,
  IntegrationType.PaymentAfterpay,
  IntegrationType.PaymentMpaisa,
];
