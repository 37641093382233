import { Ticket } from "~graphql/sdk";

// seatZone, seatSection, seatLabel comes from Ticket or SeatsIO, objectType comes from SeatsIO
export const formatSeatLocation = (
  seatZone?: Ticket["seatZone"],
  seatSection?: Ticket["seatSection"],
  seatLabel?: Ticket["seatLabel"],
  objectType?: any
) => {
  if (objectType === "GeneralAdmissionArea") return seatLabel;
  const split = seatLabel?.split("-");
  let label = seatLabel;

  if (split?.length > 2) {
    label = `Row ${split[1].trim()}, Seat ${split[2].trim()}`;
  } else if (split?.length === 2) {
    label = `Seat ${split[1].trim()}`;
  }

  const seatZoneText = `${seatZone ? `${seatZone}` : ""}${
    seatZone && (seatSection || seatLabel) ? ", " : ""
  }`;

  return `${seatZoneText}${seatSection ? `${seatSection}` : ""}${
    label ? `${seatSection ? ", " : ""}${label}` : ""
  }`;
};
