export type State<T> = { data?: T; error?: Error };

export async function handlePromise<T>(
  promise: () => Promise<T>
): Promise<State<T>> {
  return promise()
    .then((data) => ({ data, error: undefined }))
    .catch((error: Error) => ({ data: undefined, error }));
}

const delay = async (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export async function loadingPromise<T>(
  promise: () => Promise<T>,
  ms = 600
): Promise<State<T>> {
  return Promise.allSettled([promise(), delay(ms)])
    .then((data) => {
      if (data[0].status === "rejected") {
        return { data: undefined, error: data[0].reason };
      } else {
        return { data: data[0].value, error: undefined };
      }
    })
    .catch((error: Error) => ({ data: undefined, error }));
}
