import { Permission } from '~graphql/sdk';
import { useAccount } from './useAccount';

export const usePermissions = () => {
  const { user } = useAccount();

  const hasPermissions = (requiredPermissions: Permission | Permission[]) => {
    if (Array.isArray(requiredPermissions)) {
      return requiredPermissions.every(user?.permissions.includes);
    }

    return user?.permissions.includes(requiredPermissions);
  };

  return hasPermissions;
};
