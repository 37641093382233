import { domain } from "~config";
import { Organization } from "~graphql/sdk";

export const protocol =
  process.env.NODE_ENV !== "production" ? "http" : "https";

export const getUrl = (
  organizationSlug: Organization["slug"],
  customer?: boolean | undefined
) => {
  const url = `${organizationSlug}.${domain}`;

  if (process.env.NODE_ENV !== "production") {
    if (customer) {
      return `${protocol}://${url}:4000`;
    }

    return `${protocol}://${url}:3000`;
  }

  if (customer) {
    return `${protocol}://${url}`;
  }

  return `${protocol}://admin.${url}`;
};
