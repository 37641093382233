import { GraphQLClient } from "graphql-request";
import { NextPageContext } from "next";
import { CurrentOrganizationOutput } from "~graphql/sdk";
import { handleNotFoundRedirect } from "~lib/helpers";
import { getSdk } from "./sdk";

const client = (shopifyDomain = "", storefrontAccessToken = "") => {
  const shopifyApiURL = shopifyDomain.replace(
    /https:\/\/([\w\d-]+).myshopify.com\/?/,
    (_, domain) => {
      if (!domain) {
        console.warn("Incorrect or no Shopify domain supplied.");
        return "";
      }
      return `https://${domain}.myshopify.com/api/2020-04/graphql.json`;
    }
  );
  return new GraphQLClient(shopifyApiURL, {
    headers: {
      "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
    },
  });
};

export const shopifySDK = (
  shopifyDomain: string,
  storefrontAccessToken: string
) => getSdk(client(shopifyDomain, storefrontAccessToken));

export const getInitialPropsWithShopifySDK = (
  func: (ctx: NextPageContext & { sdk: ReturnType<typeof shopifySDK> }) => any
) => (ctx: NextPageContext & { organization: CurrentOrganizationOutput }) => {
  if (!ctx.organization?.shopify) return handleNotFoundRedirect(ctx);

  const { shopDomain, storefrontAccessToken } = ctx.organization.shopify;
  const sdk = shopifySDK(shopDomain, storefrontAccessToken);

  return func(Object.assign(ctx, { sdk }));
};
