import create from "zustand";
import { logger } from "~features/seated-reservation/store/middleware";
import { PaymentOption } from "../checkout.types";

export type Store = {
  paymentOptions: PaymentOption[];
  selectedPaymentOption?: PaymentOption;
  paymentPlanFeeAmount?: number;
};

const initialState: Store = {
  paymentOptions: [],
  selectedPaymentOption: undefined,
};

export const usePaymentOptionsStore = create<Store>(
  logger(
    (set, get) => ({
      ...initialState,
    }),
    "payment-options-store"
  )
);

export const resetState = () =>
  usePaymentOptionsStore.setState(initialState, true);
