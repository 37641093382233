import { BuyNowPayLaterConfig } from "~features/checkout/checkout.types";
import { FeeType } from "~graphql/sdk";

export type OmitTypename<T> = Omit<T, "__typename">;

type optionsConfig = {
  fee?: number | null;
  type?: FeeType | null;
  showHiddenFees?: boolean;
};

export const calculateBNPLPrice = (
  total: number,
  config: BuyNowPayLaterConfig,
  options: optionsConfig = {}
): number => {
  let fee = 0;

  if (options.showHiddenFees && options.fee) {
    if (options.type === FeeType.Percentage) {
      fee = total * (options.fee / 100);
    } else {
      fee = options.fee;
    }
  }

  return (total + fee) / config.terms;
};
