export interface CategoryData {
  zone: string | undefined;
  section: string | undefined;
  tags: string[] | undefined;
}

export const getCategoryDataFromLabel = (label = ""): CategoryData => {
  let zone: string | undefined;
  let section: string | undefined;
  let tags: string | undefined;

  // Split on colon
  const [zoneAndTags, sectionAndTags] = label.split(/ ?: ?/);
  if (zoneAndTags.includes("|")) {
    // Zone and tags are set
    // e.g. 'Red zone | c'
    [zone, tags] = zoneAndTags.split(/ ?\| ?/);
  } else if (sectionAndTags?.includes("|")) {
    // Zone, section and tags are set
    // e.g. 'Red zone: Section 3 | c'
    zone = zoneAndTags;
    [section, tags] = sectionAndTags.split(/ ?\| ?/);
  } else if (sectionAndTags) {
    // Zone and section are set
    // e.g. 'Red zone: Section 3'
    zone = zoneAndTags;
    section = sectionAndTags;
  } else {
    // Only zone is set
    // e.g. 'Red zone'
    zone = zoneAndTags;
  }

  return { zone, section, tags: tags ? tags.split(/ ?, ?/) : [] };
};
