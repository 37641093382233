/** @todo add releases */

import {
  SeatsIOCategory,
  SeatsIOZone,
  ZoneCategory,
  ZoneSection,
} from "~components/reservation/constants";
import { getCategoryDataFromLabel } from "./zoneFilters";

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

type Zone = RecursivePartial<SeatsIOZone>;

export const groupCategoriesIntoZones = (
  categories: SeatsIOCategory[]
): any[] => {
  const zonesMapping: Record<string, any> = {};

  categories.forEach((rawCategory) => {
    const { label, key, color } = rawCategory as {
      label: string;
      key: number | string;
      color: string;
    };

    const { zone, section, tags } = getCategoryDataFromLabel(label);

    // TODO: if zone is not in release => skip
    const category: ZoneCategory = {
      key,
      tags,
      color,
    };

    if (!zonesMapping[zone]) {
      // Append zone
      zonesMapping[zone] = {
        name: zone,
        categories: [],
        sections: {},
      };
    }
    // Append category to zone
    zonesMapping[zone].categories.push(category);
    if (section) {
      if (!zonesMapping[zone].sections[section]) {
        // Append section
        zonesMapping[zone].sections[section] = {
          name: section,
          categories: [],
        };
      }
      // Append category to section
      zonesMapping[zone].sections[section].categories.push(category);
    }
  });

  // Convert to array (and filter unique tags per zone and section)
  const zones = Object.values(zonesMapping).map((zone: any) => {
    return {
      ...zone,
      keys: zone.categories.map(({ key }) => key as number),
      tags: zone.categories.map(({ tags }) => tags as string[]).flat(),
      sections: Object.values(zone.sections as any[]).map((section) => {
        return {
          ...section,
          keys: section.categories.map(({ key }) => key as number),
          tags: section.categories.map(({ tags }) => tags as string[]).flat(),
        } as ZoneSection;
      }),
    } as ZoneCategory;
  });

  return zones;
};
