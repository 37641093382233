import { useContext } from "react";
import { BaseContext } from "~context/baseContext";
import { shopifySDK } from "~lib/shopify/client";

export const useShopifySDK = () => {
  const { organization } = useContext(BaseContext);

  return shopifySDK(
    organization?.shopify?.shopDomain,
    organization?.shopify?.storefrontAccessToken
  );
};
