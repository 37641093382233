import { GetOrderJobStatusQuery } from "~graphql/sdk";

export function buildCustomErrorMessage(
  customError: GetOrderJobStatusQuery["getOrderJobStatus"]["createOrderError"]
): string {
  const firstError = customError[0];
  if (firstError.__typename === "TicketNotAvailableError") {
    const link = `/events/${firstError.eventId}/ticket-waitlist${
      firstError.releaseId ? `?release=${firstError.releaseId}` : ""
    }`;

    const errorMessage = `
      <p> Please try again with fewer tickets.</p>
      ${
        firstError.shouldDisplayWaitlist
          ? `<br/>
      <p>You can also <a style="text-decoration: underline;" href=${link}>join the waitlist</a> and if more tickets become available,
      you'll be the first to hear.</p>`
          : ""
      }
    `;

    return errorMessage;
  }
}

export function buildCustomErrorTitle(
  customError: GetOrderJobStatusQuery["getOrderJobStatus"]["createOrderError"]
): string {
  const firstError = customError[0];
  if (firstError.__typename === "TicketNotAvailableError") {
    return "Ticket availability is limited";
  }
}
