import { useRouter } from "next/router";
import { useEffect, useState } from "react";

interface ReferralIdOrCode {
  referralId?: string;
  referralCode?: string;
}

interface ReferralData {
  referralCode: string;
  referralCodeVersion: string;
  referralIdOrCode: ReferralIdOrCode;
}

const hasSessionStorage = typeof sessionStorage !== "undefined";

export const useReferralCode = (): ReferralData => {
  const router = useRouter();
  const referralCodeInQueryParams = router?.query?.referralCode;
  const referralCodeVersionInQueryParams = router?.query?.referralCodeVersion;

  const [referralCode, setReferralCode] = useState<string>(
    typeof referralCodeInQueryParams === "string"
      ? referralCodeInQueryParams
      : hasSessionStorage
      ? sessionStorage.getItem("referralCode")
      : undefined
  );

  const [referralCodeVersion, setReferralCodeVersion] = useState<string>(
    typeof referralCodeVersionInQueryParams === "string"
      ? referralCodeVersionInQueryParams
      : hasSessionStorage
      ? sessionStorage.getItem("referralCodeVersion")
      : undefined
  );

  useEffect(() => {
    if (typeof referralCodeInQueryParams === "string") {
      setReferralCode(referralCodeInQueryParams);
      hasSessionStorage &&
        sessionStorage.setItem("referralCode", referralCodeInQueryParams);
    }
  }, [referralCodeInQueryParams]);

  useEffect(() => {
    if (typeof referralCodeVersionInQueryParams === "string") {
      setReferralCodeVersion(referralCodeVersionInQueryParams);
      hasSessionStorage &&
        sessionStorage.setItem(
          "referralCodeVersion",
          referralCodeVersionInQueryParams
        );
    }
  }, [referralCodeVersionInQueryParams]);

  const referralIdOrCode: ReferralIdOrCode = {
    referralCode: undefined,
    referralId: undefined,
  };

  if (referralCodeVersion === "2") {
    referralIdOrCode.referralCode = referralCode;
  } else {
    referralIdOrCode.referralId = referralCode;
  }

  return {
    referralCode,
    referralCodeVersion:
      referralCodeVersion === "2" ? referralCodeVersion : undefined,
    referralIdOrCode,
  };
};
