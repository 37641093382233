import { css } from "styled-components";

// Imported as global styles in CSSReset.ts because the Google Maps API
// adds the dropdown in the DOM outside of this component.
// Reference: https://developers.google.com/maps/documentation/javascript/place-autocomplete#style-autocomplete
export const mapStyles = css`
  .pac-container {
    border-radius: 8px;
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: ${(p) => p.theme.shadows.container};
    position: absolute;

    &:after {
      // Hide 'powered by Google'
      background-image: none !important;
      height: 0px;
    }

    .pac-icon {
      display: none;
    }

    span {
      margin: 0;
      padding: 0;
    }

    // An item in the list of predictions supplied by the Autocomplete or SearchBox widget.
    .pac-item {
      font-family: ${(p) => p.theme.fonts.body};
      font-size: ${(p) => p.theme.fontSizes[2]};
      font-weight: ${(p) => p.theme.fontWeights.regular as string};
      color: ${(p) => p.theme.colors.N500};
      margin: 0 6px;
      border: none;
      padding: 10px 10px;
      line-height: 1.3;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
      /* Fix for weird vertical alignment of second child */
      > span:last-child {
        margin-top: 2px;
      }
    }

    .pac-item:first-child {
      margin-top: 6px;
    }

    .pac-item:last-child {
      margin-bottom: 6px;
    }

    .pac-item:hover,
    .pac-item-selected {
      background: ${(p) => p.theme.colors.P100};
      color: ${(p) => p.theme.colors.N100};
      cursor: pointer;
      .pac-item-query {
        color: ${(p) => p.theme.colors.N100};
      }
    }
    .pac-item-selected {
    }
    .pac-item-query {
      display: inline-block;
      color: ${(p) => p.theme.colors.N800};
      font-size: ${(p) => p.theme.fontSizes[3]};
      padding-bottom: 0;
      margin-bottom: 0;
    }
    .pac-matched {
      color: inherit;
      font-weight: inherit;
    }
  }
`;
