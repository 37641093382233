import { DocumentNode, GraphQLError } from "graphql";
import useSWR, { SWRConfiguration, Key } from "swr";
import type { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";
import { client } from "~lib";
import { useContext } from "react";
import { BaseContext } from "~context";
import { FetcherResponse } from "swr/dist/types";

type Fetcher<T extends DocumentNode> = (
  variables: VariablesOf<T>
) => FetcherResponse<ResultOf<T>>;

export const generateKey = <T extends DocumentNode>(
  query: T,
  variables?: VariablesOf<T>
): Key => {
  return query ? JSON.stringify([query, variables ?? {}]) : null;
};

export const useQuery = <T extends DocumentNode>(
  query: T,
  variables?: VariablesOf<T>,
  options: SWRConfiguration<ResultOf<T>, GraphQLError> = {}
) => {
  const { organization } = useContext(BaseContext);

  const queryFn = options?.fetcher
    ? // SWR allows you provide a fetcher function in the options config. The problem is
      // that it passes the `key` and spreads it onto the function args. Because we are stringifying
      // the DocumentNode and variables as the key, this does not really work for passing the variables to
      // the function. So we need to create a new function that accepts the variables.
      () => (options.fetcher as Fetcher<T>)(variables)
    : async () => client(organization?.id).request(query, variables);

  const key = generateKey(query, variables);
  const results = useSWR<ResultOf<T>, Error>(key, queryFn, options);

  const isLoading = typeof results.data === "undefined" && !results.error;

  return { ...results, key, isLoading };
};
