import create from "zustand";
import { logger } from "~features/seated-reservation/store/middleware";
import { IReleaseTicket } from "../nonseated-reservation.types";
import { TTrackingContext } from "~lib/helpers/googleAnalyticsTracking";
import { Cart } from "~features/checkout/cart";

export type Store = {
  selectedTicketTypes: {
    [eventId: string]: IReleaseTicket[];
  };
  totals: {
    [eventId: string]: Cart;
  };
  trackingContext: TTrackingContext;
  isSubmitting: boolean;
};

const initialState: Store = {
  totals: {},
  selectedTicketTypes: {},
  trackingContext: undefined,
  isSubmitting: false,
};

export const useNonSeatedReservationStore = create<Store>(
  logger(
    (set, get) => ({
      ...initialState,
    }),
    "non-seated-reservation-store"
  )
);

export const getNonSeatedStoreState = useNonSeatedReservationStore.getState;
export const setNonSeatedStoreState = useNonSeatedReservationStore.setState;

export const resetState = () => setNonSeatedStoreState(initialState, true);
