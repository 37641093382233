export const TaxRateStates = [
  { name: "Alabama", abbreviation: "AL", taxAmount: 5 },
  { name: "Alaska", abbreviation: "AK", taxAmount: 0 },
  { name: "Arizona", abbreviation: "AZ", taxAmount: 4.54 },
  { name: "Arkansas", abbreviation: "AR", taxAmount: 7.0 },
  { name: "California", abbreviation: "CA", taxAmount: 13.3 },
  { name: "Colorado", abbreviation: "CO", taxAmount: 4.63 },
  { name: "Connecticut", abbreviation: "CT", taxAmount: 6.7 },
  { name: "Delaware", abbreviation: "DE", taxAmount: 6.75 },
  { name: "District Of Columbia", abbreviation: "DC", taxAmount: 8.95 },
  { name: "Florida", abbreviation: "FL", taxAmount: 0 },
  { name: "Georgia", abbreviation: "GA", taxAmount: 6.0 },
  { name: "Hawaii", abbreviation: "HI", taxAmount: 11.0 },
  { name: "Idaho", abbreviation: "ID", taxAmount: 7.4 },
  { name: "Illinois", abbreviation: "IL", taxAmount: 5.0 },
  { name: "Indiana", abbreviation: "IN", taxAmount: 3.4 },
  { name: "Iowa", abbreviation: "IA", taxAmount: 8.98 },
  { name: "Kansas", abbreviation: "KS", taxAmount: 4.9 },
  { name: "Kentucky", abbreviation: "KY", taxAmount: 6 },
  { name: "Louisiana", abbreviation: "LA", taxAmount: 6 },
  { name: "Maine", abbreviation: "ME", taxAmount: 5.25 },
  { name: "Maryland", abbreviation: "MD", taxAmount: 5.75 },
  { name: "Massachusetts", abbreviation: "MA", taxAmount: 5.25 },
  { name: "Michigan", abbreviation: "MI", taxAmount: 4.25 },
  { name: "Minnesota", abbreviation: "MN", taxAmount: 9.85 },
  { name: "Mississippi", abbreviation: "MS", taxAmount: 4.25 },
  { name: "Missouri", abbreviation: "MO", taxAmount: 6 },
  { name: "Montana", abbreviation: "MT", taxAmount: 6.9 },
  { name: "Nebraska", abbreviation: "NE", taxAmount: 6.84 },
  { name: "Nevada", abbreviation: "NV", taxAmount: 0 },
  { name: "New Hampshire", abbreviation: "NH", taxAmount: 5 },
  { name: "New Jersey", abbreviation: "NJ", taxAmount: 8.97 },
  { name: "New Mexico", abbreviation: "NM", taxAmount: 4.9 },
  { name: "New York", abbreviation: "NY", taxAmount: 8.82 },
  { name: "North Carolina", abbreviation: "NC", taxAmount: 7.75 },
  { name: "North Dakota", abbreviation: "ND", taxAmount: 3.99 },
  { name: "Ohio", abbreviation: "OH", taxAmount: 5.925 },
  { name: "Oklahoma", abbreviation: "OK", taxAmount: 5.25 },
  { name: "Oregon", abbreviation: "OR", taxAmount: 9.9 },
  { name: "Pennsylvania", abbreviation: "PA", taxAmount: 3.07 },
  { name: "Rhode Island", abbreviation: "RI", taxAmount: 5.99 },
  { name: "South Carolina", abbreviation: "SC", taxAmount: 7 },
  { name: "South Dakota", abbreviation: "SD", taxAmount: 0 },
  { name: "Tennessee", abbreviation: "TN", taxAmount: 6 },
  { name: "Texas", abbreviation: "TX", taxAmount: 0 },
  { name: "Utah", abbreviation: "UT", taxAmount: 5 },
  { name: "Vermont", abbreviation: "VT", taxAmount: 8.95 },
  { name: "Virginia", abbreviation: "VA", taxAmount: 5.75 },
  { name: "Washington", abbreviation: "WA", taxAmount: 0 },
  { name: "West Virginia", abbreviation: "WV", taxAmount: 6.5 },
  { name: "Wisconsin", abbreviation: "WI", taxAmount: 7.65 },
  { name: "Wyoming", abbreviation: "WY", taxAmount: 0 },
];

export const usaStates = TaxRateStates.map(
  ({ name, abbreviation, taxAmount }) => ({
    label: name,
    value: name,
    abbreviation: abbreviation,
    taxAmount: taxAmount,
  })
);
