import { apiUrl } from '~config';

export const downloadInvoice = (
  orgId: string,
  orderId: string,
  orderNumber: string,
) => {
  let anchor = document.createElement('a');

  anchor.href = `${apiUrl}/invoice/${orgId}/${orderId}`;
  anchor.target = '_blank';
  anchor.download = `order-${orderNumber}.pdf`;

  return anchor.click();
};
