import React, { ReactNode } from "react";
import { SystemProps, Text } from "flicket-ui";
import { pick } from "@styled-system/props";

export const Badge = ({
  children,
  color = "#DC3009",
  ...props
}: { children: ReactNode; color?: string } & Omit<SystemProps, "color">) => (
  <Text
    backgroundColor={color as any}
    style={{
      padding: "4px 8px",
      verticalAlign: "text-bottom",
    }}
    variant="small"
    borderRadius="full"
    fontSize={1}
    display="inline-block"
    whiteSpace="nowrap"
    lineHeight="normal"
    color="white"
    {...pick(props)}
  >
    {children}
  </Text>
);
