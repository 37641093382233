import { useState, useEffect } from "react";

import { Order, OrderStatus, OrderTransactionStatus } from "~graphql/sdk";
import { useOrganization } from "~hooks/useOrganization";
import { showToast } from "~lib/helpers";

type StepHookProps = {
  deliveryMethod: Order["deliveryMethod"] | undefined;
  buyerInformation: Order["buyerInformation"] | undefined;
  status: Order["status"] | undefined;
  transactionStatus: Order["transactionStatus"] | undefined;
  isAdmin: boolean;
};

type StepHookResponse = {
  step: number | undefined;
  navigateSteps: (newStep: number) => void;
};

type UseSteps = ({
  deliveryMethod,
  buyerInformation,
  status,
  transactionStatus,
  isAdmin,
}: StepHookProps) => StepHookResponse;

export const useSteps: UseSteps = ({
  deliveryMethod = undefined,
  buyerInformation = undefined,
  status = undefined,
  transactionStatus = undefined,
}) => {
  const [currentStep, setCurrentStep] = useState<number | undefined>(undefined);
  const { hasFeature } = useOrganization();

  const checkStep = () => {
    if (
      deliveryMethod &&
      buyerInformation &&
      (status === OrderStatus.Paid ||
        status === OrderStatus.Completed ||
        transactionStatus === OrderTransactionStatus.Success)
    ) {
      return setCurrentStep(4);
    }

    if (
      deliveryMethod &&
      buyerInformation &&
      (buyerInformation.postalCode ||
        (hasFeature("fiji") && buyerInformation.city))
    ) {
      return setCurrentStep(3);
    }

    if (deliveryMethod) {
      return setCurrentStep(2);
    }

    return setCurrentStep(1);
  };

  useEffect(() => {
    checkStep();
  }, [deliveryMethod, buyerInformation, status, transactionStatus]);

  const navigateSteps = (newStep: number) => {
    if (currentStep === newStep) {
      return;
    }

    if (
      newStep < currentStep ||
      (newStep === 2 && deliveryMethod) ||
      (newStep === 3 &&
        buyerInformation &&
        (buyerInformation.postalCode ||
          (hasFeature("fiji") && buyerInformation.city))) ||
      (newStep === 4 &&
        (status === OrderStatus.Paid || status === OrderStatus.Completed))
    ) {
      return setCurrentStep(newStep);
    }

    return showToast("Please fill out the form in order to continue", "error");
  };

  return {
    step: currentStep,
    navigateSteps,
  };
};
