import React from "react";
import { Box, system } from "flicket-ui";
import styled from "styled-components";
import { color } from "styled-system";

const StyledRow = styled(Box)`
  border-radius: ${(p) => p.theme.radii.none};
  /* background: ${(p) => p.theme.colors.N100}; */

  border: none;
  border-bottom: 1px solid ${(p) => p.theme.colors.N200};

  cursor: pointer;

  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: none;
  }

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    border-radius: ${(p) => p.theme.radii.sm};
    /* background: ${(p) => p.theme.colors.white}; */

    border: 1px solid ${(p) => p.theme.colors.N200};

    &:hover {
      box-shadow: ${(p) => p.theme.shadows.hover};
    }
  }

  && {
    ${color}
  }

  ${system}
`;

export const Row = ({ children, ...props }) => {
  return <StyledRow {...props}>{children}</StyledRow>;
};
