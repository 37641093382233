import { uploadsFolder } from "~config";
import { ExtendedFile } from "~graphql/sdk";

export const getImageSrc = (
  file: ExtendedFile | string,
  fallback: string = undefined
): string =>
  file
    ? typeof file === "string"
      ? file
      : `${uploadsFolder}/${file?.directory}/${file?.fileName}`
    : fallback ?? "/static/no-image.jpg";
