import { useRouter } from "next/router";
import { useQuery } from "~hooks/useQuery";
import { CompetitionByIdDocument } from "~graphql/typed-document-nodes";
export const usePreviewCompetition = () => {
  const router = useRouter();
  const query = router.query;
  const competitionId = query.previewCompetitionById as string | undefined;
  const queryMethods = useQuery(
    competitionId ? CompetitionByIdDocument : null,
    {
      id: competitionId,
    }
  );
  return {
    competition: queryMethods?.data?.competition,
    ...queryMethods,
  };
};

export const useWillShowPreviewCompetition = () => {
  const router = useRouter();
  const query = router.query;
  return !!query.previewCompetitionById;
};
