import { FeeType, OrderQuery } from "~graphql/sdk";
import { OrderGateway } from "./getTotals";

export const getTransactionFee = (
  method: string,
  order: OrderQuery["order"],
  orderGateway: OrderGateway
): number => {
  let result = orderGateway?.transactionFee2;

  if (
    method?.includes("-variant-") &&
    orderGateway?.transactionFee2 !== undefined &&
    orderGateway?.transactionFeeType2 === FeeType.Percentage
  ) {
    const variantId = method.split("-variant-")[1];

    const foundFee =
      order?.event?.paymentPlanSettings?.scheduleOptions.find(
        (option) => option?.id === variantId
      )?.stripeFeePercentage ??
      order?.membership?.paymentPlanSettings?.scheduleOptions.find(
        (option) => option?.id === variantId
      )?.stripeFeePercentage ??
      0;

    if (foundFee) {
      result = +(result + foundFee).toFixed(2);
    }
  }

  if (
    method?.includes("afterpay") &&
    typeof orderGateway?.bnplTransactionFeePercent === "number"
  ) {
    result = orderGateway.bnplTransactionFeePercent;
  }

  return result;
};
