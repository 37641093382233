type result = "light" | "dark";

// https://awik.io/determine-color-bright-dark-using-javascript/
export const lightOrDarkColor = (color: string): result => {
  // Variables for red, green, blue values
  let r: number, g: number, b: number;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    const rgbArray: RegExpMatchArray = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = +rgbArray[1];
    g = +rgbArray[2];
    b = +rgbArray[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    const rgbNumber: number = +(
      "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
    );

    r = rgbNumber >> 16;
    g = (rgbNumber >> 8) & 255;
    b = rgbNumber & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp: number = Math.sqrt(
    0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)
  );

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return "light";
  } else {
    return "dark";
  }
};

type ColorProps = {
  dark?: string;
  light?: string;
  background: string;
};

export const getLightOrDarkColor = ({
  background,
  dark = "#000000",
  light = "#FFFFFF",
}: ColorProps): string =>
  lightOrDarkColor(background) === "light" ? dark : light;
