import { sdk } from "~lib";

export const pointFetcher = async (_: string, orgId: string) =>
  sdk({ orgId })
    .point()
    .then((res) => res.point);

export const pointItemFetcher = async (_: string, orgId: string, id: string) =>
  sdk({ orgId })
    .pointItem({ id })
    .then((res) => res.pointItem);

export const pointItemsVisibleFetcher = async (_: string, orgId: string) =>
  sdk({ orgId })
    .pointItems()
    .then((res) => res.pointItems)
    .then((items) => items.filter((item) => item.isVisible));
