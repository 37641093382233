import { useContext } from "react";
import { BaseContext } from "~context";
import { sdk } from "~lib";

export const useSDK = () => {
  const { organization } = useContext(BaseContext);

  if (!organization) {
    console.warn(
      "There is no or invalid organization set, check the subdomain"
    );
  }

  return sdk({ orgId: organization?.id });
};
