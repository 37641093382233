import { useEffect, useState } from 'react';

let _id = 0;
const genId = () => ++_id;

export const useId = (): number => {
  const [id, setId] = useState<number>(null);
  useEffect(() => setId(genId()), []);
  return id;
};
