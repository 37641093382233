export const getAfterPayLocaleByCurrency = (currency: string) => {
  //  This is a mapping of currency to locale for AfterPay (it will only get us so far before we need proper country codes)
  switch (currency) {
    case "AUD":
      return "en_AU";
    case "CAD":
      return "en_CA";
    case "DKK":
      return "da_DK";
    case "EUR":
      return "en_GB";
    case "NZD":
      return "en_NZ";
    case "SEK":
      return "sv_SE";
    case "USD":
      return "en_US";
    case "GBP":
      return "en_GB";
    default:
      return "en_NZ";
  }
};
