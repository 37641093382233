import { apiUrl } from "~config";
import * as Sentry from "@sentry/nextjs";

export const downloadTicket = async (
  orgId: string,
  ticketIds: string | string[],
  eventTicketsForMembership?: boolean,
  format = "pdf"
) => {
  const eventTicketsForMembershipQuery = eventTicketsForMembership
    ? `?eventTicketsForMembership=${eventTicketsForMembership}`
    : "";

  const url = `${apiUrl}/ticket/${orgId}/${
    Array.isArray(ticketIds) ? ticketIds.join() : ticketIds
  }${eventTicketsForMembershipQuery}?format=${format}`;

  // eslint-disable-next-line no-useless-catch
  try {
    const response = await fetch(url, {
      method: "GET",
      credentials: "include", // Ensure cookies are sent with the request
      headers: {
        "Flicket-Org-Id": orgId,
      },
    });

    const disposition = response.headers.get("Content-Disposition");
    const match = disposition?.match(/filename="(.+?)"/);
    const filename = match ? match[1] : "ticket.pdf";
    if (!response.ok) {
      throw new Error("Failed to download ticket");
    }

    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);

    // Create an anchor tag to download the file
    const anchor = document.createElement("a");
    anchor.href = blobUrl;
    anchor.download = filename;
    document.body.appendChild(anchor);
    anchor.click();

    // Clean up by revoking the object URL and removing the anchor element
    URL.revokeObjectURL(blobUrl);
    anchor.remove();
  } catch (error) {
    throw error;
  }
};

// TEMP: Just wanna know how often this happens
export const captureDownloadTicketFailure = (
  dataSource: string,
  operation?: string,
  tickets?: any
) => {
  Sentry.captureException(new Error("No active tickets to download"), {
    tags: {
      operation: operation ?? "unknown",
      dataSource: dataSource,
    },
    extra: {
      tickets: JSON.stringify(tickets),
    },
  });
};
