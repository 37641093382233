import React from "react";
import { NextSeo, NextSeoProps } from "next-seo";

export type SeoProps = {
  title: string;
  description?: string;
  options?: NextSeoProps;
};

export const Seo = ({ title, description, options = {} }: SeoProps) => {
  return <NextSeo title={title} description={description} {...options} />;
};
