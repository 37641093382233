const EMPTY_SLATE = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

export const parse = (raw: string) => {
  try {
    return JSON.parse(raw);
  } catch (e) {
    return EMPTY_SLATE;
  }
};
