import { sdk } from "~lib";
import {
  GetReferralCampaignByEventIdQuery,
  GetReferralCampaignByOrderIdQuery,
  ReferralRewardQuantityType,
} from "~graphql/sdk";

export const useReferral = () => {
  const calculateReferralDiscount = (
    referralCampaign:
      | GetReferralCampaignByEventIdQuery["getReferralCampaignByEventId"]
      | GetReferralCampaignByOrderIdQuery["getReferralCampaignByOrderId"],
    total: number
  ) => {
    const result =
      referralCampaign?.referralUserRewardQuantityType ===
      ReferralRewardQuantityType.FlatRate
        ? referralCampaign?.referralUserRewardQuantity
        : (total * referralCampaign?.referralUserRewardQuantity) / 100;

    return result ? result : 0;
  };

  // const referralCampaignExplaination = (
  //   referralCampaign:
  //     | GetReferralCampaignByEventIdQuery["getReferralCampaignByEventId"]
  //     | GetReferralCampaignByOrderIdQuery["getReferralCampaignByOrderId"]
  // ) => {};

  const myReferralRewards = async (_: string, orgId: string, id: string) =>
    sdk({ orgId }).getReferralRewardByOrderId({ id });

  const getReferralCampaigns = async (_: string, orgId: string) =>
    sdk({ orgId }).getReferralCampaigns();

  const getReferralCampaignByOrderId = async (
    _: string,
    orgId: string,
    id: string
  ) => sdk({ orgId }).getReferralCampaignByOrderId({ id });

  const getReferralCampaignByEventId = async (
    _: string,
    orgId: string,
    id: string
  ) =>
    sdk({ orgId }).getReferralCampaignByEventId({
      id,
    });

  return {
    calculateReferralDiscount,
    // referralCampaignExplaination,
    myReferralRewards,
    getReferralCampaignByOrderId,
    getReferralCampaignByEventId,
    getReferralCampaigns,
  };
};
