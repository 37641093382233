import { FC } from "react";
import { ScreenSizeProvider, theme as baseTheme } from "flicket-ui";
import { ThemeProvider } from "styled-components";

import {
  BaseProvider,
  UserProvider,
  ReservationProvider,
  TicketNamingProvider,
  ShopifyProvider,
} from "~context";
import { CurrentOrganizationQuery } from "~graphql/sdk";
import { ReCaptchaProvider } from "~context/recaptchaContext";
import { AnalyticsProvider } from "~context/analyticsContext";
import ErrorBoundary from "~components/errorBoundary";

export const appTheme = {
  ...baseTheme,
  shadows: {
    ...baseTheme.shadows,
    card: "0px 0px 8px 1px rgba(0, 0, 0, 0.15)",
  },
  fonts: {
    ...baseTheme.fonts,
    body: "Figtree, sans-serif",
    heading: "Figtree, sans-serif",
  },
  colors: {
    ...baseTheme.colors,
    link: "#124A6C",
    error: "#DC3009",
    error25: "#fdf5f3",
    error50: "#DC3009",
    error100: "#DC3009",
  },
};

export const Providers: FC<{
  organization: CurrentOrganizationQuery["currentOrganization"];
}> = ({ children, organization }) => {
  const organizationColors = {
    ...(organization?.branding?.N100 && { N100: organization.branding.N100 }),
    ...(organization?.branding?.N200 && { N200: organization.branding.N200 }),
    ...(organization?.branding?.N300 && { N300: organization.branding.N300 }),
    ...(organization?.branding?.N400 && { N400: organization.branding.N400 }),
    ...(organization?.branding?.N500 && { N500: organization.branding.N500 }),
    ...(organization?.branding?.N600 && { N600: organization.branding.N600 }),
    ...(organization?.branding?.N700 && { N700: organization.branding.N700 }),
    ...(organization?.branding?.N800 && { N800: organization.branding.N800 }),
    ...(organization?.branding?.P100 && { P100: organization.branding.P100 }),
    ...(organization?.branding?.P200 && { P200: organization.branding.P200 }),
    ...(organization?.branding?.P300 && { P300: organization.branding.P300 }),
    ...(organization?.branding?.S100 && { S100: organization.branding.S100 }),
    ...(organization?.branding?.S200 && { S200: organization.branding.S200 }),
    ...(organization?.branding?.S300 && { S300: organization.branding.S300 }),
  };

  const brandedTheme = {
    ...appTheme,
    colors: {
      ...appTheme.colors,
      ...organizationColors,
    },
  };

  const recaptchaKey = process.env.RECAPTCHA_SITE_KEY;

  return (
    <ThemeProvider theme={brandedTheme}>
      <BaseProvider organization={organization}>
        <UserProvider>
          {/* ErrorBoundary currently relies on the theme, the organization and 
              the user for the components it renders on the page. Until that changes
              it needs to be decalred after. */}
          <ErrorBoundary organization={organization}>
            <AnalyticsProvider>
              <ScreenSizeProvider>
                <ReservationProvider>
                  <ShopifyProvider>
                    <TicketNamingProvider>
                      <ReCaptchaProvider reCaptchaKey={recaptchaKey}>
                        {children}
                      </ReCaptchaProvider>
                    </TicketNamingProvider>
                  </ShopifyProvider>
                </ReservationProvider>
              </ScreenSizeProvider>
            </AnalyticsProvider>
          </ErrorBoundary>
        </UserProvider>
      </BaseProvider>
    </ThemeProvider>
  );
};

export const NoOrgProviders: FC = ({ children }) => {
  return (
    <ThemeProvider theme={appTheme}>
      <BaseProvider
        organization={{} as CurrentOrganizationQuery["currentOrganization"]}
      >
        <ScreenSizeProvider>
          <UserProvider>{children}</UserProvider>
        </ScreenSizeProvider>
      </BaseProvider>
    </ThemeProvider>
  );
};
