import React from 'react';
import { Box, SystemProps } from 'flicket-ui';
import styled from 'styled-components';

const MainLayout = styled(Box)<SystemProps>`
  position: relative;

  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 128px;

  width: 100%;
  max-width: 100vw;

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    padding-top: 72px;
  }
`;

export const Main = ({ children, ...props }) => (
  <MainLayout {...props}>{children}</MainLayout>
);
