import flatten from "lodash/flatten";
import orderBy from "lodash/orderBy";
import partition from "lodash/partition";
import { addPricingToZone } from "./pricing";

import {
  CoverTypes,
  AlcoholFreeZoneTypes,
  SeatsIOZone,
  SortTypes,
} from "~components/reservation/constants";
import { Release } from "~graphql/sdk";

type FilterZoneProps = {
  zones: SeatsIOZone[];
  releasedZones: Release["releaseZones"];
  options: {
    coverType: CoverTypes;
    alcoholFreeZone: AlcoholFreeZoneTypes;
  };
};

/**
 * currently we only have two type of tags:
 *  - c = 'covered'
 *  - f = 'family zone' (a.k.a alcohol free zone)
 */
const includesTag = (array: string[], tag: "c" | "f") => array.includes(tag);

const isCovered = (coverType: CoverTypes) => coverType === CoverTypes.COVERED;
const isUnCovered = (coverType: CoverTypes) =>
  coverType === CoverTypes.UNCOVERED;
const isCoverAll = (coverType: CoverTypes) => coverType === CoverTypes.ALL;

const isAlcoholFreeZone = (alcoholFreeZone: AlcoholFreeZoneTypes) =>
  alcoholFreeZone === AlcoholFreeZoneTypes.YES;
const noAlcoholFreeZone = (alcoholFreeZone: AlcoholFreeZoneTypes) =>
  alcoholFreeZone === AlcoholFreeZoneTypes.NO;
const isFamilyAll = (alcoholFreeZone: AlcoholFreeZoneTypes) =>
  alcoholFreeZone === AlcoholFreeZoneTypes.NO_PREFERENCE;

export const findZones = (
  zones: SeatsIOZone[],
  releasedZones: Release["releaseZones"],
  coverType: CoverTypes,
  alcoholFreeZone: AlcoholFreeZoneTypes
) => {
  const allZones = [];

  zones?.forEach((zone) => {
    if (
      !releasedZones?.find(
        ({ zone: rZone, isActive }) => isActive && rZone.name === zone.name
      )
    ) {
      return; // do nothing if the zone is not released
    }

    /**
     * filters:
     *  - coverType === all
     *  - AlcoholFreeZone === no-preference
     */
    if (isCoverAll(coverType) && isFamilyAll(alcoholFreeZone)) {
      return allZones.push({
        ...zone,
      });
    }

    /**
     * filters:
     *  - coverType === all
     *  - AlcoholFreeZone === yes
     */
    if (
      isCoverAll(coverType) &&
      isAlcoholFreeZone(alcoholFreeZone) &&
      includesTag(zone.tags, "f")
    ) {
      return allZones.push({
        ...zone,
        sections: zone?.sections?.filter((section) =>
          includesTag(section.tags, "f")
        ),
      });
    }

    /**
     * filters:
     *  - coverType === all
     *  - AlcoholFreeZone === no
     */
    if (
      isCoverAll(coverType) &&
      noAlcoholFreeZone(alcoholFreeZone) &&
      (!includesTag(zone.tags, "f") || zone.sections.length > zone.tags.length)
    ) {
      return allZones.push({
        ...zone,
        sections: zone?.sections?.filter(
          (section) => !includesTag(section.tags, "f")
        ),
      });
    }

    /**
     * filters:
     *  - coverType === covered
     *  - AlcoholFreeZone === no-preference
     */
    if (
      isCovered(coverType) &&
      isFamilyAll(alcoholFreeZone) &&
      includesTag(zone.tags, "c")
    ) {
      return allZones.push({
        ...zone,
        sections: zone?.sections?.filter((section) =>
          includesTag(section.tags, "c")
        ),
      });
    }

    /**
     * filters:
     *  - coverType === covered
     *  - AlcoholFreeZone === yes
     */
    if (
      isCovered(coverType) &&
      isAlcoholFreeZone(alcoholFreeZone) &&
      includesTag(zone.tags, "c") &&
      includesTag(zone.tags, "f")
    ) {
      return allZones.push({
        ...zone,
        sections: zone?.sections?.filter(
          (section) =>
            includesTag(section.tags, "c") && includesTag(section.tags, "f")
        ),
      });
    }

    /**
     * filters:
     *  - coverType === covered
     *  - AlcoholFreeZone === no
     */
    if (
      isCovered(coverType) &&
      noAlcoholFreeZone(alcoholFreeZone) &&
      includesTag(zone.tags, "c") &&
      (!includesTag(zone.tags, "f") || zone.sections.length > zone.tags.length)
    ) {
      return allZones.push({
        ...zone,
        sections: zone?.sections?.filter(
          (section) =>
            includesTag(section.tags, "c") && !includesTag(section.tags, "f")
        ),
      });
    }

    /**
     * filters:
     *  - coverType === uncovered
     *  - AlcoholFreeZone === no-preference
     */
    if (
      isUnCovered(coverType) &&
      isFamilyAll(alcoholFreeZone) &&
      (!includesTag(zone.tags, "c") || zone.sections.length > zone.tags.length)
    ) {
      return allZones.push({
        ...zone,
        sections: zone?.sections?.filter(
          (section) => !includesTag(section.tags, "c")
        ),
      });
    }

    /**
     * filters:
     *  - coverType === uncovered
     *  - AlcoholFreeZone === yes
     */
    if (
      isUnCovered(coverType) &&
      isAlcoholFreeZone(alcoholFreeZone) &&
      (!includesTag(zone.tags, "c") ||
        zone.sections.length > zone.tags.length) &&
      includesTag(zone.tags, "f")
    ) {
      return allZones.push({
        ...zone,
        sections: zone?.sections?.filter(
          (section) =>
            !includesTag(section.tags, "c") && includesTag(section.tags, "f")
        ),
      });
    }

    /**
     * filters:
     *  - coverType === uncovered
     *  - AlcoholFreeZone === no
     */
    if (
      isUnCovered(coverType) &&
      noAlcoholFreeZone(alcoholFreeZone) &&
      (!includesTag(zone.tags, "c") ||
        zone.sections.length > zone.tags.length) &&
      (!includesTag(zone.tags, "f") || zone.sections.length > zone.tags.length)
    ) {
      return allZones.push({
        ...zone,
        sections: zone?.sections?.filter(
          (section) =>
            !includesTag(section.tags, "c") && !includesTag(section.tags, "f")
        ),
      });
    }
  });

  return allZones;
};

export const filterReleasedZonesByOptions = ({
  zones,
  releasedZones,
  options: { coverType, alcoholFreeZone },
}: FilterZoneProps): any[] => {
  return findZones(zones, releasedZones, coverType, alcoholFreeZone);
};

export const getZoneKeys = (zone): number[] => {
  const keys = zone?.sections?.length
    ? flatten(
        zone?.sections
          ?.filter(
            (s) => s.sectionTicketTypes?.length > 0 || s.ticketTypes?.length > 0
          )
          .map((s) => s?.keys)
      )
    : zone?.keys;

  return keys as number[];
};

export const getSelectableZones = (releasedZones, zones, options, isAdmin) => {
  const filteredZones = filterReleasedZonesByOptions({
    releasedZones,
    zones,
    options: {
      coverType: options.coverType,
      alcoholFreeZone: options.zoneType,
    },
  });

  const pricedZones = addPricingToZone(
    filteredZones,
    releasedZones,
    "ticketTypes",
    isAdmin
  );

  // best-available: filter by the max price of price range, display from most expensive to least expensive
  // price: filter by the min price of price range, display from least expensive to most expensive
  const selectableZones = orderBy(
    pricedZones,
    options.selectedSort === SortTypes.PRICE
      ? "priceRange.min"
      : "priceRange.max",
    options.selectedSort === SortTypes.PRICE ? "asc" : "desc"
  );

  return selectableZones;
};

export interface CategoryData {
  zone: string | undefined;
  section: string | undefined;
  tags: string[] | undefined;
}

export const getCategoryDataFromLabel = (label: string): CategoryData => {
  let zone: string | undefined;
  let section: string | undefined;
  let tags: string | undefined;

  // Split on colon
  const [zoneAndTags, sectionAndTags] = label.split(/ ?: ?/);
  if (zoneAndTags.includes("|")) {
    // Zone and tags are set
    // e.g. 'Red zone | c'
    [zone, tags] = zoneAndTags.split(/ ?\| ?/);
  } else if (sectionAndTags?.includes("|")) {
    // Zone, section and tags are set
    // e.g. 'Red zone: Section 3 | c'
    zone = zoneAndTags;
    [section, tags] = sectionAndTags.split(/ ?\| ?/);
  } else if (sectionAndTags) {
    // Zone and section are set
    // e.g. 'Red zone: Section 3'
    zone = zoneAndTags;
    section = sectionAndTags;
  } else {
    // Only zone is set
    // e.g. 'Red zone'
    zone = zoneAndTags;
  }

  return { zone, section, tags: tags ? tags.split(/ ?, ?/) : [] };
};

export const sortAvailableItems = (data, currentSort: SortTypes) => {
  let [available] = partition(
    data,
    (d) => d.ticketTypes?.length > 0 || d.sectionTicketTypes?.length > 0
  );

  if (currentSort === SortTypes.PRICE) {
    available = available
      ?.slice(0)
      ?.sort((a, b) => a?.priceRange?.min - b?.priceRange?.min);
  }

  return available?.sort((a, b) => a?.isSoldOut - b?.isSoldOut);
};

export const getAvailableCategoriesForChart = (zones) =>
  flatten(
    zones
      ?.filter((zone) => zone.ticketTypes?.length)
      .map((zone) => getZoneKeys(zone))
  );
