import {
  CurrentOrganizationQuery,
  EventQuery,
  GetI18nQuery,
  SeoMetadata,
} from "~graphql/sdk";

// Used to store global state on the client.
// Initially created to store the organization after
// it has been loaded on the server so we don't have to load it on
// every client page load.

export type ClientCacheProps = {
  organization: CurrentOrganizationQuery["currentOrganization"] | null;
  i18n?: GetI18nQuery["getI18n"];
  seoMetadata: Pick<
    SeoMetadata,
    | "title"
    | "description"
    | "origin"
    | "eventId"
    | "membershipId"
    | "keywords"
    | "openGraphImageUrl"
  >;
};

export const clientCache: ClientCacheProps = {
  organization: undefined,
  i18n: undefined,
  seoMetadata: undefined,
};
