import React from 'react';
import { Img } from 'flicket-ui';

interface QuestionMarkProps {
  onClick: () => void;
}

export const QuestionMark = ({ onClick }: QuestionMarkProps) => (
  <div onClick={onClick}>
    <Img
      cursor={'pointer'}
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAEbUlEQVRoge3aW4iVVRQH8J86OcdL6YxO+lbS1RLzpafSCKI0JawoqCwIDAu8YZH0UD30IkQvgRD0FPTSS/QSkpQ6aZFldnlIxy4QZdIFpYuTYnF62Ptrf4PnzJzzfXvGAv/w8Z05e+//Wuvstddee+3hPP5bmJSRqx/LcAOuwWW4GDNi+0n8iG/wBfbhXZzIqENlNPAgduJvNLt8/sJbWIPeCdYdTMPj+KGk1CnswtNYjYXowwXx6YvfrcYz2B3HFOOPYovw40wIVuLrkgIfYS1mVeCajUdwoMT3FVZk0bQNGnipJPBj3JqRfzk+KfFvNw6zM19QvCks2g2YkltI5NyEYWm25+UiXyBMdxOHsCgX8ShYjKEo88uoQy0MlAg/xNy6hF2gTwjRTWFNzq9K1JDc6X1pP5hIzMAHkptVWjPFwj4kbHbnCnMkr9je7eCV0sKuuiamC5FoI7biYVxdkWuxFACWdzpomrRPbKggdAqeElKPVrv5XlxXgXeztPg7crEnpH2i2xDbg9eNnZoM46YK3J/G8ZvH6tyQ0o4qm91WIxXehfVCPvYC/ii1HcPMLvlXxLHfGyM3WyNFiG4xHcclRZ9v0ecq/Fbq82iXMiZJkfT+0TrujJ3WdikAbi8p+K3gCq2wrdTvtQpy1sWxO9p16BfS6lOqJYBLhZT8qNazUeA+yZDBCnL6cBpntNFzteTX44n1kiFvVOTYE8ffUXwxudR4Y3zvrkjeCSbjodLf71Xk2RPfhc4jDFkY359VJO8Ej+H6+PkkXqnIU+jYcoM9IkzXwlaNGXAJfpXc6skaXNdGjsOtGn+JjXNqCGiHHimTbcbPdc4zcyPPz60aT8fGqTUEtMNzkhEncGlNvl6pTnAWxsuQZUJYLwy5OwPnqIaMh2tNweeSES9n4h3VtcZjsZd3++PynWvOWuzl8Hskvq/MJIyQWxXYLxiTA4WOhc4jDCmsq3JWaIdyhnosI2+hY8sZ2RffN2cUOIS343MwI2+h495WjX1S0jg7o9Dc6JeSxouKL8szcgLvCO5wz4Sq1h3uFbaIncLZpiUeEKLBgUxCVwnnj21C8aEuJgku2hSOA23RKxwjm7gtg+BXjSx218WqyPWdDq4htsTOB9Wv7+Y0pEfIeptCiWlMNKRa76aawnMaUvzAQ7q4FCqqFcNCcawqrsAt8amzPy3BnypWd7ZLv8B4pPadYkAoyjXxYhWChuAOTaGQfC6K2DOF1KYZ35XvGQeENKC4VhjIoV2H6BfO9MV1XO0LnwXS1A7Jm4u1w5KSzCPqH8T+xTzJzYaF2mu7Alwd9AjRqVjY+4W7+qxoSAGgKRSUc92+ThKuMop9oljY43r3vlya9qJqv05IOrtFv1D/LdKOwpVy3haPiobgXkU60xQy0kE8izuFU9wcIcGbGj8vwl2xz6BUJyjSjo3O0X9A9ApV8R1GFhg6fc7gTSEBrGVAzn+qmSVUTJYK5/7LhXB9YWz/HT8JofSwcCgaNEoqfh7/Z/wDDFFhrEWRtKMAAAAASUVORK5CYII="
      width={'20px'}
    />
  </div>
);
