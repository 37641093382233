import React, { useEffect, createContext, FC } from "react";
import { CurrentOrganizationQuery, LandingPage } from "~graphql/sdk";
import * as Sentry from "@sentry/nextjs";

interface BaseContextProps {
  organization: CurrentOrganizationQuery["currentOrganization"];
  isSinglePage?: boolean;
}

const BaseContext = createContext<BaseContextProps>(undefined);

const BaseProvider: FC<BaseContextProps> = ({ children, organization }) => {
  useEffect(() => {
    if (organization) {
      // Use org slug for Sentry logs for easier readability in Sentry
      Sentry.setTag("organization", organization.slug);
    }
  }, [organization]);

  return (
    <BaseContext.Provider
      value={{
        organization,
        isSinglePage: organization?.landingPage === LandingPage.SingleEvent,
      }}
    >
      {children}
    </BaseContext.Provider>
  );
};

export { BaseProvider, BaseContext };
