import { Flex, PrimaryButton, Text } from "flicket-ui";
import { omit } from "lodash";
import { useRouter } from "next/router";

import { Footer, Seo, Menu } from "~components";
import { Layout } from "~components/common/Layout";

const getError = ({ res, err }) => {
  let statusCode = 404;

  if (res) {
    statusCode = res?.statusCode || err?.statusCode || 500;
  }

  return { statusCode };
};

const getContent = (statusCode: number) => {
  let content = "An unexpected error ocurred.";

  if (statusCode === 404)
    content = "We could not find the page you were looking for."; // not found

  if (statusCode === 500)
    content = "Our server had some trouble processing this request."; // internal

  if (statusCode === 401) content = "You are unauthorized to be here."; // unAuthorized

  return content;
};

const Error = ({ statusCode }: { statusCode: number }) => {
  return <ErrorBody statusCode={statusCode} />;
};

// Main Error component uses getInitialProps as it can resond to the server errors.
Error.getInitialProps = ({ res, err }) => getError({ res, err });

// Create the error body as a separate component that does not use getInitialProps
// or else it will interfere with pages that have getServersideProps and break.
// This component body is used in the main ErrorBoundary component declared in providers.tsx
function ErrorBody({ statusCode }: { statusCode: number }) {
  const router = useRouter();

  return (
    <>
      <Seo title="Error" description="Error page" options={{ noindex: true }} />
      <Menu />
      <Layout layoutType="main">
        <Flex
          w="100%"
          alignItems="center"
          flexDir="column"
          justifyContent={{ _: "flex-start", md: "center" }}
          flex="1"
          px={{ _: 2, md: 4 }}
          py={2}
          bg="N100"
        >
          <Flex
            alignItems="center"
            flexDir="column"
            justifyContent="center"
            w="100%"
            maxW="480px"
            bg="white"
            px={{ _: 2, md: 4 }}
            py={4}
            borderRadius="lg"
            boxShadow="container"
          >
            <Text variant="header.S" textAlign="center" mb={{ _: 0, md: 1 }}>
              {statusCode}
            </Text>
            <Text variant="regular" textAlign="center" mb={{ _: 0, md: 1 }}>
              {getContent(statusCode)}
            </Text>
            <PrimaryButton onClick={() => router.reload()} mt={{ _: 2, lg: 3 }}>
              Try again
            </PrimaryButton>
          </Flex>
        </Flex>
        <Footer />
      </Layout>
    </>
  );
}

export default Error;

export { ErrorBody };
