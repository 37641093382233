import React, { PropsWithChildren } from "react";

import {
  DialogContent as ReachDialogContent,
  DialogOverlay as ReachDialogOverlay,
} from "@reach/dialog";
import { system, SystemProps } from "flicket-ui";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";
import { useIsMobile } from "~hooks";

const MotionOverlay = motion(ReachDialogOverlay);

const MotionContent = motion(ReachDialogContent);

const DialogOverlay = styled(MotionOverlay)<{ zIndex?: number }>`
  background-color: rgba(0, 0, 0, 0.4);

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: ${(p) => (p.zIndex ? p.zIndex : 1501)};

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    overflow: auto;

    padding-top: ${(p) => p.theme.space[4]}px;
    padding-bottom: ${(p) => p.theme.space[4]}px;
  }
  ${system}
`;

const DialogContent = styled(MotionContent)<SystemProps>`
  height: 100%;
  width: 100%;
  max-width: 1014px;

  background-color: ${(p) => p.theme.colors.white};

  overflow: auto;
  display: flex;
  flex-direction: column;

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    overflow: visible;
    height: auto;
    max-height: 100%;
    background-color: ${(p) => p.theme.colors.transparent};
  }

  ${system}
`;

export type ModalProps = SystemProps & {
  isOpen: boolean;
  onClose: () => void;
};

export const Modal = ({
  isOpen,
  onClose,
  children,
  ...props
}: PropsWithChildren<ModalProps>) => {
  const isMobile = useIsMobile();
  return (
    <AnimatePresence exitBeforeEnter>
      {isOpen && (
        <DialogOverlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          isOpen={isOpen}
          onDismiss={onClose}
        >
          {/* @ts-expect-error fix this */}
          <DialogContent
            initial={{
              y: isMobile ? 100 : -100,
            }}
            animate={{
              y: 0,
            }}
            exit={{
              y: isMobile ? 1000 : -1000,
            }}
            {...props}
          >
            {children}
          </DialogContent>
        </DialogOverlay>
      )}
    </AnimatePresence>
  );
};
