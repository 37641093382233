import * as Sentry from "@sentry/nextjs";
import { hotjar } from "react-hotjar";
import { UserFragment } from "~graphql/sdk";
import posthogJS from "posthog-js";

export const login = ({ id, email }: UserFragment) => {
  Sentry.configureScope((scope) =>
    scope.setUser({
      id,
      email,
      ip_address: "{{auto}}",
    })
  );

  if (hotjar.initialized()) {
    hotjar.identify(id, { email });
  }
  posthogJS.identify(id, {
    email,
  });
};

export const logout = () => {
  Sentry.configureScope((scope) => scope.setUser(null));
  posthogJS.reset();
};
