import { startOfDay } from "date-fns";

export const today = startOfDay(new Date()).toISOString();

export function convertTo12HrTime(time24 = ""): string {
  if (!time24) return "";
  const [hours, minutes] = time24.split(":");
  const hour = parseInt(hours, 10);
  const period = hour >= 12 ? "pm" : "am";
  const hour12 = hour % 12 || 12;
  return `${hour12}:${minutes}${period}`;
}
