import useSWR from "swr";
import { PointItemQuery, PointItemsQuery, PointQuery } from "~graphql/sdk";
import { useOrganization } from "~hooks/useOrganization";
import {
  pointFetcher,
  pointItemFetcher,
  pointItemsVisibleFetcher,
} from "../graphql/fetchers";

interface PointsServiceProps {
  id?: string;
  enabled?: boolean;
  includeItems?: boolean;
}

interface PointsServiceReturn {
  error: Error;
  point: PointQuery["point"];
  pointItem: PointItemQuery["pointItem"];
  pointItems: PointItemsQuery["pointItems"];
  isLoading: boolean;
  isPointsEnabled: boolean;
}

const swrConfig = {
  shouldRetryOnError: false,
};

export const usePointsService = ({
  id,
  enabled = true,
  includeItems = false,
}: PointsServiceProps = {}): PointsServiceReturn => {
  const { organization, hasFeature } = useOrganization();

  const isPointsEnabled =
    enabled && hasFeature("points") && organization.point?.isEnabled;

  const { data: point, error: pointError } = useSWR<PointQuery["point"], Error>(
    isPointsEnabled ? ["point", organization.id] : null,
    pointFetcher,
    swrConfig
  );

  const { data: pointItem, error: pointItemError } = useSWR<
    PointItemQuery["pointItem"],
    Error
  >(
    isPointsEnabled && id ? ["point-item", organization.id, id] : null,
    pointItemFetcher,
    swrConfig
  );

  const { data: pointItems, error: pointItemsError } = useSWR<
    PointItemsQuery["pointItems"],
    Error
  >(
    isPointsEnabled && includeItems ? ["pointItems", organization.id] : null,
    pointItemsVisibleFetcher,
    swrConfig
  );

  const error =
    isPointsEnabled && (pointError || pointItemError || pointItemsError);

  const isLoading = (() => {
    if (error || !isPointsEnabled) return false;

    if (!point) return true;
    if (id && !pointItem) return true;
    if (includeItems && !pointItems) return true;
  })();

  return {
    error,
    point,
    pointItem,
    pointItems,
    isLoading,
    isPointsEnabled,
  };
};
