import { useState } from "react";
import { useFacebookSDK } from "./useFacebookSDK";

interface FacebookLoginHookArgs {
  onSuccess: (accessToken: string) => void;
}

interface FacebookLoginHook {
  isFacebookLoaded: boolean;
  isFacebookLoading: boolean;
  handleFacebookLogin: () => void;
}

export const useFacebookLogin = ({
  onSuccess,
}: FacebookLoginHookArgs): FacebookLoginHook => {
  const { isFacebookLoaded } = useFacebookSDK();
  const [isFacebookLoading, setFacebookLoading] = useState(false);

  const handleFacebookLogin = () => {
    if (!isFacebookLoaded || isFacebookLoading) {
      return;
    }

    setFacebookLoading(true);

    window.FB.login(
      (response) => {
        if (response?.status === "connected") {
          onSuccess(response?.authResponse?.accessToken);
        }

        setFacebookLoading(false);
      },
      { scope: "email" }
    );
  };

  return {
    isFacebookLoaded,
    isFacebookLoading,
    handleFacebookLogin,
  };
};
