import { GetServerSidePropsResult } from "next";
import { ActiveCompetitionByTypeQuery, CompetitionType } from "~graphql/sdk";
import { sdk, handlePromise, getImageSrc, getError } from "~lib";
import { Result } from "~lib/type-utils";

export interface CompetitionSeoProps {
  activeCompetition?: Result<
    ActiveCompetitionByTypeQuery["activeCompetitionByType"]
  >;
  eventTitle?: Result<string>;
  eventImageUrls?: Result<string[]>;
}

export const asOpenGraphImage = (url: string, eventTitle: string) => ({
  width: 1200,
  height: 630,
  alt: eventTitle,
  url,
});

export const competitionSeo = async (
  organizationId: string,
  eventId: string
): Promise<GetServerSidePropsResult<CompetitionSeoProps>> => {
  const orgSdk = sdk({
    orgId: organizationId,
    isServer: true,
  });

  const { data: competition, error: competitionError } = await handlePromise(
    async () =>
      orgSdk.activeCompetitionByType({
        input: {
          competitionType: CompetitionType.Registration,
          eventId,
        },
      })
  );

  const { data: event, error: eventError } = await handlePromise(async () =>
    orgSdk.event({
      id: eventId,
    })
  );

  const banner = getImageSrc(
    event?.event?.backgroundImage ?? event?.event?.backgroundImageFullPage,
    ""
  );
  const logo = getImageSrc(event?.event?.logo, "");

  return {
    props: {
      activeCompetition: {
        data: competition?.activeCompetitionByType,
        error: getError(competitionError, "graphQL"),
      },
      eventTitle: {
        data: event?.event?.title,
        error: getError(eventError, "graphQL"),
      },
      eventImageUrls: {
        data: [logo, banner].filter((image) => !!image),
        error: getError(eventError, "graphQL"),
      },
    },
  };
};
