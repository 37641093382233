import { Flex, Spinner } from "flicket-ui";
import hoistNonReactStatics from "hoist-non-react-statics";
import { NextComponentType } from "next";
import Router from "next/router";
import { useEffect, useState } from "react";
import { useAccount } from "~hooks";

const getDisplayName = (Component: NextComponentType) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  Component.displayName || Component.name || "Component";

interface Options {
  redirect?: boolean;
}

export function withAuth(Component: NextComponentType, options: Options = {}) {
  const AuthComponent = (props: any) => {
    const {
      user,
      isLoggedIn,
      hasCheckedLogin,
      isTwoFactorAuthenticated,
      isInitialising,
      hasLoaded,
      authState,
    } = useAccount();

    const [allowAuth, setAllowAuth] = useState(false);

    useEffect(() => {
      if (authState === "authenticated") {
        setAllowAuth(true);
      } else if (authState === "unauthenticated") {
        const url = new URL(props.url as string);
        void Router.push({
          pathname: "/login",
          query: options.redirect
            ? {
                redirect: url.pathname + url.search,
              }
            : "",
        });
      } else if (authState === "requires-2fa") {
        void Router.replace({ pathname: `/authenticate` });
      }
    }, [
      authState,
      hasCheckedLogin,
      user,
      isLoggedIn,
      isTwoFactorAuthenticated,
      hasLoaded,
      isInitialising,
    ]);

    if (!allowAuth) {
      return (
        <Flex variant="center" flex="1" p={4} flexDirection="column">
          <Spinner size={48} color="P300" data-testid="spinner" />
        </Flex>
      );
    }

    return <Component {...props} />;
  };

  hoistNonReactStatics(AuthComponent, Component);

  AuthComponent.displayName = `withAuth(${getDisplayName(Component)})`;

  return AuthComponent;
}
