/* eslint-disable @typescript-eslint/restrict-template-expressions */

import {
  IReleaseTicket,
  IReleaseZone,
} from "~features/nonseated-reservation/nonseated-reservation.types";
import {
  MembershipsSoldForUserQuery,
  OrderQuery,
  TicketsSoldForUserQuery,
} from "~graphql/sdk";

import ReactGA from "react-ga4";
import { isVisible } from "~components/reservation/non-seated/non-seated.helpers";
import { Release } from "~graphql/fetchers";
import { SeatsIOSeat } from "~components/reservation/constants";
import { domainAllowsStateLogging } from "~config";

/* eslint-disable @typescript-eslint/no-unsafe-return */
declare interface DataLayerWindow extends Window {
  dataLayer: unknown[];
  GA_INITIALIZED?: boolean;
}
declare const window: DataLayerWindow;

const track = (event) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(event);

  if (domainAllowsStateLogging) {
    console.debug(
      `%c[TRACK V1] ${event.event}`,
      "background: #ccc; color: #333",
      {
        event,
      }
    );
  }
};

const trackV4 = (eventName: string, eventData) => {
  const isWindowAvailable = typeof window !== "undefined";
  const isGAInitialized = isWindowAvailable && window.GA_INITIALIZED;
  if (isGAInitialized) {
    ReactGA.event(eventName, eventData);
  }

  if (domainAllowsStateLogging) {
    console.debug(
      `%c[TRACK  V4] ${eventName}`,
      "background: #ccc; color: #333",
      {
        eventName,
        eventData,
      }
    );
  }
};

export type TTrackingContext = {
  currency: string;
  id: string;
  ticketType: IReleaseTicket;
  name: string;
  releaseId: string;
  releaseName: string;
};

type TTrackingData = Omit<TTrackingContext, "orgId" | "currency">;

export const trackCheckout = (
  order,
  context: Pick<TTrackingContext, "currency">
) => {
  track({
    event: "begin_checkout",
    ecommerce: {
      transaction_id: order?.orderNumber,
      affiliation: "Flicket Online Store",
      value: order?.total,
      tax: order?.totalTax,
      shipping: order?.deliveryFee,
      currency: context.currency,
      coupon: order?.promoCode?.code,
      items: order?.lineItems?.edges
        ?.filter(({ node }) => node?.total > 0)
        ?.map(({ node }) => ({
          item_name: node?.name,
          item_id:
            node.ticketType?.id ||
            node.membershipType?.id ||
            node.eventAddon?.id ||
            node.membershipAddon?.id,
          price: node?.price,
          item_price: node?.price,
          item_category: node?.type,
          quantity: node?.quantity,
        })),
    },
    customerDetails: {
      billing_first_name: order.user ? order.user.firstName : null,
      billing_last_name: order.user ? order.user.lastName : null,
      billing_email: order.user ? order.user.email : null,
      billing_phone: order.user ? order.user.phoneNumber : null,
      billing_postal_code: order.user
        ? order.user.shippingAddress?.postalCode
        : null,
      billing_state: order.user ? order.user.shippingAddress?.postalCode : null,
      billing_city: order.user ? order.user.shippingAddress?.city : null,
      billing_country: order.user ? order.user.shippingAddress?.country : null,
    },
  });

  trackV4("begin_checkout", {
    event_id: order?.event?.id || order?.membership?.id,
    value: order?.total,
    currency: context.currency,
    items: order?.lineItems?.edges
      ?.filter(({ node }) => node?.total > 0)
      ?.map(({ node }) => ({
        item_id:
          node.ticketType?.id ||
          node.membershipType?.id ||
          node.eventAddon?.id ||
          node.membershipAddon?.id,
        item_name:
          node.ticketType?.name ||
          node.membershipType?.membership?.name ||
          node.name,
        affiliation: order?.event?.title ?? order?.membership?.name,
        item_list_id: order.release?.id,
        item_list_name: order.release?.slug,
        quantity: node?.quantity,
        item_category: node?.seatZone,
        price: node?.price,
      })),
  });
};

export const trackTransactionReady = (order) => {
  track({
    event: "transactionReady",
    transactionId: order?.orderNumber,
    transactionAffiliation: order?.event?.title || order?.membership?.name,
    transactionTotal: order?.total,
    transactionTax: order?.totalTax,
    transactionProducts: order?.lineItems?.edges
      ?.filter(({ node }) => node?.total > 0)
      ?.map(({ node: lineItem }) => ({
        sku:
          lineItem.ticketType?.id ||
          lineItem.membershipType?.id ||
          lineItem.eventAddon?.id ||
          lineItem.membershipAddon?.id,
        name: lineItem.name,
        category: lineItem.type,
        item_category: lineItem.type,
        item_price: lineItem?.price,
        price: lineItem.price,
        quantity: lineItem.quantity,
      })),
  });
};

export const trackPurchase = (
  order: OrderQuery["order"],
  context: Pick<TTrackingContext, "currency">
) => {
  track({
    event: "purchase",
    ecommerce: {
      transaction_id: order?.orderNumber,
      affiliation: order?.event?.title || order?.membership?.name,
      value: order?.total,
      tax: order?.totalTax,
      shipping: order?.deliveryFee,
      currency: context.currency,
      items: order?.lineItems?.edges
        ?.filter(({ node }) => node?.total > 0)
        ?.map(({ node }) => ({
          item_name: node?.name,
          item_id:
            node.ticketType?.id ||
            node.membershipType?.id ||
            node.eventAddon?.id ||
            node.membershipAddon?.id,
          item_price: node?.price,
          price: node?.price,
          category: node?.type,
          item_category: node?.type,
          quantity: node?.quantity,
        })),
      customerDetails: {
        billing_first_name: order.user ? order.user.firstName : null,
        billing_last_name: order.user ? order.user.lastName : null,
        billing_email: order.user ? order.user.email : null,
        billing_phone: order.user ? order.user.phoneNumber : null,
        billing_postal_code: order.user
          ? order.user.shippingAddress?.postalCode
          : null,
        billing_state: order.user
          ? order.user.shippingAddress?.postalCode
          : null,
        billing_city: order.user ? order.user.shippingAddress?.city : null,
        billing_country: order.user
          ? order.user.shippingAddress?.country
          : null,
      },
    },
  });

  trackV4("purchase", {
    event_id: order?.event?.id || order?.membership?.id,
    currency: context.currency,
    transaction_id: order?.id,
    value: order?.total,
    items: order?.lineItems?.edges
      ?.filter(({ node }) => node?.total > 0)
      ?.map(({ node }) => ({
        item_id:
          node.ticketType?.id ||
          node.membershipType?.id ||
          node.eventAddon?.id ||
          node.membershipAddon?.id,
        item_name:
          node.ticketType?.name ||
          node.membershipType?.membership?.name ||
          node.name,
        affiliation: order?.event?.title || order?.membership?.name,
        item_list_id: order.release?.id,
        item_list_name: order.release?.slug,
        quantity: node?.quantity,
        item_category: node?.seatZone,
        price: node?.price,
        shipping: order?.deliveryFee,
        tax: order?.totalTax,
      })),
  });
};

export const trackSelectItem = (data: TTrackingData) => {
  const { id, ticketType, name, releaseId, releaseName } = data;

  if (!ticketType) return;
  track({
    event: "select_item",
    ecommerce: {
      items: [
        {
          item_id: ticketType.id,
          item_list_name: ticketType.name,
          quantity: ticketType.quantity,
          price: ticketType.price,
        },
      ],
    },
  });

  trackV4("select_item", {
    event_id: id,
    items: [
      {
        item_id: ticketType.id,
        item_name: ticketType.name,
        affiliation: name,
        item_list_id: releaseId,
        item_list_name: releaseName,
        item_category: ticketType.zoneId,
        quantity: ticketType.quantity,
        price: ticketType.price,
      },
    ],
  });
};

export const trackAddToCart = (data: TTrackingContext) => {
  const { id, ticketType, name, releaseId, releaseName } = data;

  if (!ticketType) return;

  track({
    event: "add_to_cart",
    ecommerce: {
      items: [
        {
          item_id: ticketType.id,
          item_list_name: ticketType.name,
          quantity: ticketType.quantity,
          price: ticketType.price,
        },
      ],
    },
  });

  trackV4("add_to_cart", {
    event_id: id,
    currency: data.currency,
    value: ticketType.price * ticketType.quantity,
    items: [
      {
        item_id: ticketType.id,
        item_name: ticketType.name,
        affiliation: name,
        item_list_id: releaseId,
        item_list_name: releaseName,
        item_category: ticketType.zoneId,
        quantity: ticketType.quantity,
        price: ticketType.price,
      },
    ],
  });
};

export const trackRemoveFromCart = (data: TTrackingContext) => {
  const { id, ticketType, name, releaseId, releaseName } = data;

  track({
    event: "remove_from_cart",
    ecommerce: {
      items: [
        {
          item_id: ticketType.id,
          item_list_name: ticketType.name,
          quantity: ticketType.quantity,
          price: ticketType.price,
        },
      ],
    },
  });

  trackV4("remove_from_cart", {
    event_id: id,
    currency: data.currency,
    value: ticketType.quantity * ticketType.price,
    items: [
      {
        item_id: ticketType.id,
        item_name: ticketType.name,
        affiliation: name,
        item_list_id: releaseId,
        item_list_name: releaseName,
        item_category: ticketType.zoneId,
        quantity: ticketType.quantity,
        price: ticketType.price,
      },
    ],
  });
};

export const trackViewItemList = (data: {
  id: string;
  name: string;
  zones: IReleaseZone[];
  allTicketTypes: IReleaseTicket[];
  selectedTicketTypes: IReleaseTicket[];
  ticketsSoldForUser:
    | TicketsSoldForUserQuery["ticketsSoldForUser"]
    | MembershipsSoldForUserQuery["membershipsSoldForUser"];
  isAdmin: boolean;
  viewableAddons: IReleaseTicket[];
  release: Release;
}) => {
  const {
    id,
    name,
    zones,
    allTicketTypes,
    selectedTicketTypes,
    ticketsSoldForUser,
    isAdmin,
    release,
    viewableAddons,
  } = data;

  let index = 0;

  const tickets = zones?.flatMap((zone) => {
    if (!zone.isActive) {
      return null;
    }
    return allTicketTypes?.map((ticketType) => {
      const zoneTypes = zone?.ticketTypes;
      const zoneTicketType = zoneTypes?.find(({ id }) => id === ticketType.id);

      if (
        !zoneTicketType ||
        !zoneTicketType.isActive ||
        (!isVisible(selectedTicketTypes, ticketType, ticketsSoldForUser) &&
          !isAdmin)
      ) {
        return null;
      }

      index += 1;

      return {
        name: `${zone?.name ?? ""} - ${ticketType?.name ?? ""}`,
        price: zoneTicketType.price,
        zoneId: zone?.name,
        index,
        ...ticketType,
      };
    });
  });

  const ticketTypes = [...viewableAddons, ...tickets].filter(
    (tt) => tt?.name.length > 0
  );

  trackV4("view_item_list", {
    event_id: id,
    item_list_id: id,
    item_list_name: name,
    items: ticketTypes.map((tt: IReleaseTicket & { index: number }) => ({
      item_id: tt.id,
      item_name: tt.name,
      index: tt.index,
      item_category: tt.zoneId,
      item_list_name: release?.name,
      item_list_id: release?.id,
      price: tt.price,
    })),
  });

  track({
    event: "view_item_list",
    ecommerce: {
      items: tickets,
    },
  });
};

export const trackViewItem = (
  data: TTrackingContext & {
    isSeated: boolean;
    ticketType: IReleaseTicket | SeatsIOSeat;
    price?: number;
  }
) => {
  const {
    id,
    ticketType,
    isSeated,
    releaseName,
    releaseId,
    name,
    price,
  } = data;

  const getTransformedItem = (isSeated: boolean) => {
    if (!isSeated) {
      const tt: IReleaseTicket = ticketType as IReleaseTicket;
      return {
        item_id: tt.id,
        item_name: tt.name,
        affiliation: name,
        item_category: tt.zoneId,
        item_list_name: releaseName,
        item_list_id: releaseId,
        price: tt.price,
      };
    } else {
      const tt: SeatsIOSeat = ticketType as SeatsIOSeat;
      return {
        item_id: tt.ticketType.id,
        item_name: tt.ticketType.name,
        affiliation: name,
        item_category: tt.category?.label,
        item_list_name: releaseName,
        item_list_id: releaseId,
        price: tt.price ?? price,
      };
    }
  };

  trackV4("view_item", {
    event_id: id,
    currency: data.currency,
    value: ticketType.price ?? price,
    items: [getTransformedItem(isSeated)],
  });
};
