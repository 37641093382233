import React, { FC, useContext } from "react";
import { system, SystemProps } from "flicket-ui";
import ReactSelect, { Props, components } from "react-select";
import styled, { css, DefaultTheme, ThemeContext } from "styled-components";

import { Icon } from "~components/common/Icon";
import { getLightOrDarkColor } from "~lib/helpers";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon
        className="chevron"
        icon="arrow-dropdown"
        fontSize={props?.small ? 2 : 3}
        mr={2}
      />
    </components.DropdownIndicator>
  );
};

type StyledSelectProps = SystemProps & {
  small: boolean;
};

const StyledSelect = styled(ReactSelect)<StyledSelectProps>(
  ({ styledTheme: theme, small }) => css`
    font-size: ${small ? "14px" : "16px"};
    font-weight: ${small
      ? theme.fontWeights.demiBold
      : theme.fontWeights.medium};
    line-height: 100%;

    ${system}

    .react-select {
      &__placeholder {
        color: ${theme.colors.N500};
        margin: 0;
        font-weight: ${theme.fontWeights.medium};
      }

      &__input {
        padding: -2px 0;
        height: 0;
      }

      &__control {
        transition: border-color 0.18s ease;
        border-radius: ${theme.radii.sm};
        box-shadow: ${theme.shadows.inner.sm};
        border-color: ${theme.colors.N200};
        min-height: ${small ? "32px" : "48px"};

        &:hover {
          border-color: ${theme.colors.N300};
        }

        .react-select__dropdown-indicator {
          padding: ${small ? 0 : "8px"};

          .chevron {
            margin-right: 8px;
            transition: all 0.25s cubic-bezier(0.09, 1.08, 0.77, 1.18);
          }

          svg {
            color: ${theme.colors.P300};
          }
        }

        &--menu-is-open {
          .react-select__dropdown-indicator {
            .chevron {
              transform: rotate(180deg);
            }
          }
        }

        &--is-disabled {
          background: ${theme.colors.N100};
          color: ${theme.colors.N500};

          .react-select__placeholder {
            color: ${theme.colors.N500};
          }
        }
      }

      &__option {
        padding: ${small ? "6px" : "8px"};
        margin: 0 8px;
        width: calc(100% - 16px);
        line-height: 1;
        border-radius: ${theme.radii.xs};
        color: ${theme.colors.N700};
        font-size: 16px;
        font-weight: ${theme.fontWeights.medium};

        &--is-focused,
        &--is-selected {
          color: ${getLightOrDarkColor({
            dark: theme.colors.N700,
            background: theme.colors.P100,
          })};
        }
      }

      &__menu {
        border: none;
        border-radius: ${theme.radii.sm};
        box-shadow: ${theme.shadows.hover};
        margin-top: 4px;

        &-list {
          padding-top: 8px;
          padding-bottom: 8px;
        }
      }

      &__value-container {
        padding: ${small ? "0 8px 8px 8px " : "8px 8px 8px 16px"};
      }

      &__indicator-separator {
        display: none;
      }
    }
  `
);

interface SelectProps extends Props {
  theme?: any;
  small?: boolean;
}

export const Select: FC<SelectProps> = (props) => {
  const styledTheme = useContext(ThemeContext); // react-select and styled-components both need a theme so it needs to be renamed

  return (
    <StyledSelect
      components={{ DropdownIndicator }}
      className="react-select"
      classNamePrefix="react-select"
      styledTheme={styledTheme}
      theme={(theme: DefaultTheme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: styledTheme.colors.P100,
          primary50: styledTheme.colors.P100,
          primary: styledTheme.colors.P100,
        },
      })}
      {...props}
    />
  );
};

export default Select;
