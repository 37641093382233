import { log } from "~features/seated-reservation/helpers/stateLogger";
import {
  setNonSeatedStoreState,
  getNonSeatedStoreState,
} from "../non-seated-reservation-store";
import { TTrackingContext } from "~lib/helpers/googleAnalyticsTracking";

type Payload = Partial<TTrackingContext>;

export function updateTrackingContext(payload: Payload) {
  log("updateTrackingContext", payload);

  const state = getNonSeatedStoreState();

  setNonSeatedStoreState({
    trackingContext: {
      ...state.trackingContext,
      ...payload,
    },
  });
}
