import { BNPLIntegration } from "~features/checkout/checkout.types";
import { BNPL_CONFIG } from "~features/checkout/constants";
import { calculateBNPLPrice, OrganizationFeatures } from "~lib";
import { useOrganization } from "./useOrganization";

export const useBNPLPriceCalculation = (
  totalPrice: number,
  bnplIntegration: Partial<BNPLIntegration>
) => {
  const { hasFeature } = useOrganization();
  const config = BNPL_CONFIG[bnplIntegration?.type];

  const showHiddenFees = hasFeature(OrganizationFeatures.ShowHiddenFees);

  const price = calculateBNPLPrice(totalPrice, config, {
    showHiddenFees,
    fee: bnplIntegration?.transactionFee,
    type: bnplIntegration?.transactionFeeType,
  });

  return {
    bnplPrice: price,
    showHiddenFees,
    bnplIntegration,
  };
};
