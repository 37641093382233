import React, { ReactNode } from 'react';
import { Flex } from 'flicket-ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

type TransitionAnimationProps = {
  transitionType?: 'opacity' | 'dropIn';
  children: ReactNode;
};

const StyledMotion = styled(motion.div)`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const getTransition = (type) =>
  ({
    opacity: {
      initial: {
        opacity: 0,
      },
      animate: {
        opacity: 1,
      },
      exit: {
        opacity: 0,
      },
    },
    dropIn: {
      initial: {
        y: -100,
      },
      animate: {
        y: 0,
      },
      exit: {
        y: 100,
      },
    },
  }[type]);

export const TransitionAnimation = ({
  transitionType = 'opacity',
  children,
}: TransitionAnimationProps) => {
  const transition = getTransition(transitionType);

  if (transitionType) {
    return (
      <Flex flex="1" flexDir="column">
        {children}
      </Flex>
    );
  }

  return (
    <StyledMotion
      initial={transition.initial}
      animate={transition.animate}
      exit={transition.exit}
    >
      {children}
    </StyledMotion>
  );
};
