import { ServerResponse } from "http";
import { NextApiResponse, NextPageContext } from "next";
import { AppPageProps } from "~pages/_app";

export const handleNotFoundRedirect = (
  ctx: NextPageContext
): { props: AppPageProps["props"] } => {
  if (ctx?.res && ctx.pathname !== "/not-found") {
    ctx.res.writeHead(302, {
      Location: "/not-found",
    });
    ctx.res.end();
  }

  return {
    props: {} as AppPageProps["props"],
  };
};

export const noOrgFoundRedirect = (res: ServerResponse): { props: any } => {
  res.writeHead(302, {
    Location: "/not-found",
  });
  res.end();

  return {
    props: {},
  };
};
