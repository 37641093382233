import { useContext, useEffect } from "react";

import { BaseContext } from "~context";
import { sdk } from "~lib";

import {
  ApplyPromoCode,
  ApplyPromoCodeMutation,
  CreatePaymentInput,
  OrderQuery,
  RemovePromoCodeMutation,
} from "~graphql/sdk";
import { showToast, getError, handlePromise } from "~lib/helpers";

type PaymentHookProps = {
  orderId: OrderQuery["order"]["id"];
};

type PaymentHookResponse = {
  createPayment: (
    input: CreatePaymentInput,
    redirectTo: (url: string) => void
  ) => Promise<void>;
  applyCoupon: (
    input: ApplyPromoCode
  ) => Promise<ApplyPromoCodeMutation["applyPromoCodeToOrder"]>;
  removeCoupon: () => Promise<
    RemovePromoCodeMutation["removePromoCodeFromOrder"]
  >;
};

type UsePayment = ({ orderId }: PaymentHookProps) => PaymentHookResponse;

export const usePayment: UsePayment = ({
  orderId,
}: PaymentHookProps): PaymentHookResponse => {
  const { organization } = useContext(BaseContext);

  const createPayment = async (
    input: CreatePaymentInput,
    redirectTo: (url: string) => void
  ) => {
    const { error, data } = await handlePromise(async () =>
      sdk({ orgId: organization?.id }).createPayment({
        id: orderId,
        input,
      })
    );

    // graphQL error
    if (error) {
      return showToast(getError(error, "graphQL"), "error");
    }

    // payment provider error
    if (data.createPayment.result.__typename === "PaymentError") {
      return showToast(data.createPayment.result.message, "error");
    }

    // success redirect
    if (data.createPayment.result.__typename === "PaymentSuccess") {
      return redirectTo(data.createPayment.result.redirectTo);
    }
  };

  const applyCoupon = async (
    input: ApplyPromoCode
  ): Promise<ApplyPromoCodeMutation["applyPromoCodeToOrder"]> => {
    const { error, data } = await handlePromise(async () =>
      sdk({ orgId: organization.id }).applyPromoCode({ id: orderId, input })
    );

    if (error) {
      showToast(getError(error, "graphQL"), "error");
    }

    return data?.applyPromoCodeToOrder;
  };

  const removeCoupon = async (): Promise<
    RemovePromoCodeMutation["removePromoCodeFromOrder"]
  > => {
    const { error, data } = await handlePromise(async () =>
      sdk({ orgId: organization.id }).removePromoCode({ id: orderId })
    );

    if (error) {
      showToast(getError(error, "graphQL"), "error");
    }

    return data?.removePromoCodeFromOrder;
  };

  useEffect(() => {
    // if in an iframe and parent href ends with windcave-payment, send message to parent window
    // more details here https://www.windcave.com/developer-e-commerce-api-rest#iframe_embeddedHPP
    try {
      if (top?.location?.href?.includes("windcave-payment")) {
        top.postMessage(
          {
            type: "windcave-payment-processing",
            url: window.location.href,
          },
          "*"
        );
      }
    } catch (e) {
      // in case of cross-origin access error
      console.error(e);
    }
  }, []);

  return {
    createPayment,
    applyCoupon,
    removeCoupon,
  };
};
