import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  createContext,
  ReactNode,
} from "react";

// Code taken and modified from https://github.com/t49tran/react-google-recaptcha-v3

interface ReCaptchaProviderProps {
  reCaptchaKey: string | undefined;
  language?: string;
  useRecaptchaNet?: boolean;
  useEnterprise?: boolean;
  scriptProps?: {
    nonce?: string;
    defer?: boolean;
    async?: boolean;
    appendTo?: "head" | "body";
    id?: string;
  };
  children: ReactNode;
}

interface ReCaptchaConsumerProps {
  executeRecaptcha?: (action?: string) => Promise<string>;
}

const cleanGstaticRecaptchaScript = () => {
  const script = document.querySelector(
    `script[src^='https://www.gstatic.com/recaptcha/releases']`
  );

  if (script) {
    script.remove();
  }
};

const cleanGoogleRecaptcha = (scriptId: string) => {
  // remove badge
  const nodeBadge = document.querySelector(".grecaptcha-badge");
  if (nodeBadge && nodeBadge.parentNode) {
    document.body.removeChild(nodeBadge.parentNode);
  }

  // remove script
  const script = document.querySelector(`#${scriptId}`);
  if (script) {
    script.remove();
  }

  cleanGstaticRecaptchaScript();
};

const generateGoogleRecaptchaSrc = ({
  useRecaptchaNet,
  useEnterprise,
}: {
  useRecaptchaNet: boolean;
  useEnterprise: boolean;
}) => {
  const hostName = useRecaptchaNet ? "recaptcha.net" : "google.com";
  const script = useEnterprise ? "enterprise.js" : "api.js";

  return `https://www.${hostName}/recaptcha/${script}`;
};

interface InjectReCaptchaScriptParams {
  reCaptchaKey: string;
  useRecaptchaNet: boolean;
  useEnterprise: boolean;
  onLoad: () => void;
  onError: () => void;
  language?: string;
  scriptProps?: {
    nonce?: string;
    defer?: boolean;
    async?: boolean;
    appendTo?: "head" | "body";
    id?: string;
  };
}

export const injectGoogleReCaptchaScript = ({
  reCaptchaKey,
  language,
  onLoad,
  useRecaptchaNet,
  useEnterprise,
  scriptProps: {
    nonce = "",
    defer = false,
    async = false,
    id = "",
    appendTo = undefined,
  } = {},
}: InjectReCaptchaScriptParams) => {
  const scriptId = id || "google-recaptcha-v3";

  // Script has already been injected, just call onLoad
  if (document.querySelector(`#${scriptId}`)) {
    onLoad();

    return;
  }

  // Generate the js script
  const googleRecaptchaSrc = generateGoogleRecaptchaSrc({
    useEnterprise,
    useRecaptchaNet,
  });
  const js = document.createElement("script");
  js.id = scriptId;
  js.src = `${googleRecaptchaSrc}?render=${reCaptchaKey}${
    language ? `&hl=${language}` : ""
  }`;

  if (nonce) {
    js.nonce = nonce;
  }

  js.defer = !!defer;
  js.async = !!async;
  js.onload = onLoad;

  // Append it to the body // head
  const elementToInjectScript =
    appendTo === "body"
      ? document.body
      : document.getElementsByTagName("head")[0];

  elementToInjectScript.appendChild(js);
};

const ReCaptchaContext = createContext<ReCaptchaConsumerProps>({
  executeRecaptcha: () => {
    // This default context function is not supposed to be called
    throw Error(
      "GoogleReCaptcha Context has not yet been implemented, if you are using useReCaptcha hook, make sure the hook is called inside component wrapped by RecaptchaProvider"
    );
  },
});

const { Consumer: ReCaptchaConsumer } = ReCaptchaContext;

export function ReCaptchaProvider({
  reCaptchaKey,
  useEnterprise = false,
  useRecaptchaNet = false,
  scriptProps,
  language,
  children,
}: ReCaptchaProviderProps) {
  const [reCaptchaInstance, setReCaptchaInstance] = useState<null | {
    execute: Function;
  }>(null);

  useEffect(() => {
    if (!reCaptchaKey) {
      console.error("ReCaptchaProvider: recaptcha key not provided");

      return;
    }

    const scriptId = scriptProps?.id || "google-recaptcha-v3";

    const onLoad = () => {
      if (!window || !(window as any).grecaptcha) {
        console.error(`ReCaptchaProvider: recaptcha script is not available`);

        return;
      }

      const grecaptcha = useEnterprise
        ? (window as any).grecaptcha.enterprise
        : (window as any).grecaptcha;

      grecaptcha.ready(() => {
        setReCaptchaInstance(grecaptcha);
      });
    };

    const onError = () => {
      console.error("ReCaptchaProvider: Error loading google recaptcha script");
    };

    injectGoogleReCaptchaScript({
      reCaptchaKey,
      useEnterprise,
      useRecaptchaNet,
      scriptProps,
      language,
      onLoad,
      onError,
    });

    return () => {
      cleanGoogleRecaptcha(scriptId);
    };
  }, [useEnterprise, useRecaptchaNet, scriptProps, language, reCaptchaKey]);

  const executeRecaptcha = useCallback(
    async (action?: string) => {
      if (!reCaptchaInstance || !reCaptchaInstance.execute) {
        throw new Error("ReCaptchaProvider: recaptcha has not been loaded");
      }

      const result = await reCaptchaInstance.execute(reCaptchaKey, { action });

      return result;
    },
    [reCaptchaInstance]
  );

  const reCaptchaContextValue = useMemo(
    () => ({
      executeRecaptcha: reCaptchaInstance ? executeRecaptcha : undefined,
    }),
    [executeRecaptcha, reCaptchaInstance]
  );

  return (
    <ReCaptchaContext.Provider value={reCaptchaContextValue}>
      {children}
    </ReCaptchaContext.Provider>
  );
}

export { ReCaptchaConsumer, ReCaptchaContext };
