import { OrderQuery } from "~graphql/sdk";

export const getPaymentCount = (
  method: string,
  order: OrderQuery["order"]
): number => {
  const paymentCount = 1;
  if (method?.includes("-variant-")) {
    const variantId = method.split("-variant-")[1];

    if (order.event)
      return (
        order?.event?.paymentPlanSettings?.scheduleOptions.find(
          (option) => option?.id === variantId
        )?.installmentCount ?? 1
      );

    if (order.membership) {
      return (
        order?.membership?.paymentPlanSettings?.scheduleOptions.find(
          (option) => option?.id === variantId
        )?.installmentCount ?? 1
      );
    }
  }

  return paymentCount;
};
