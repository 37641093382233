import React from "react";

import { addYears } from "date-fns";
import { InputWrapper } from "flicket-ui";
import Flatpickr from "react-flatpickr";

import Styles from "./styles";
import { Icon } from "../Icon";

const now = new Date();

const defaultOptions = {
  date: {
    dateFormat: "d - m - Y",
    maxDate: addYears(now, 2),
  },
  time: {
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
    time_24hr: true,
  },
  dateTime: {
    dateFormat: "d - m - Y H:i",
    maxDate: addYears(now, 2),
    enableTime: true,
    time_24hr: true,
  },
};

interface IDatePicker {
  label?: string;
  error?: string;
  type?: keyof typeof defaultOptions;
  onChange?: (dateString: string | string[]) => void;
  name: string;
  options?: any;
  value?: any;
  disabled?: boolean;
}

export const DatePicker = ({
  name,
  onChange,
  label,
  type = "date",
  error,
  options,
  value,
  disabled,
  ...props
}: IDatePicker) => {
  return (
    <InputWrapper label={label} name={name} error={error} flex="1" {...props}>
      <Styles isValid={!error}>
        <Flatpickr
          options={{
            static: true,
            ...defaultOptions[type],
            ...options,
          }}
          onChange={(val) => {
            onChange(
              val.length > 1
                ? val.map((date) => date.toISOString())
                : val?.[0]?.toISOString()
            );
          }}
          value={value}
          disabled={disabled}
        />
        <Icon icon="calendar-today" fontSize={6} className="calendar-icon" />
      </Styles>
    </InputWrapper>
  );
};
