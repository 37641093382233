import { FC, useEffect, useState, createContext } from "react";
import useSWR from "swr";
import { useShopifySDK } from "~hooks/useShopifySDK";
import {
  CheckoutBaseFragmentFragment,
  CheckoutLineItemInput,
} from "~lib/shopify/sdk";

const LOCAL_STORAGE_CHECKOUT_ID = "checkoutId";

export const ShopifyContext = createContext<{
  checkout: CheckoutBaseFragmentFragment;
  updatingCart: boolean;
  addItemToCart: (lineItem: CheckoutLineItemInput) => void;
  adjustLineItemQuantity: (lineItem: CheckoutLineItemInput) => void;
  removeLineItem: (arg: { variantId: string }) => void;
}>(undefined);

export const ShopifyProvider: FC = ({ children }) => {
  const sdk = useShopifySDK();
  const [checkoutId, setCheckoutIdState] = useState("");
  const [updatingCart, setUpdatingCart] = useState(false);

  const setCheckoutId = (id: string) => {
    setCheckoutIdState(id);
    window.localStorage.setItem(LOCAL_STORAGE_CHECKOUT_ID, id);
  };

  useEffect(() => {
    const checkoutId = window.localStorage.getItem(LOCAL_STORAGE_CHECKOUT_ID);
    if (checkoutId) setCheckoutIdState(checkoutId);
  }, []);

  const { data: checkout, mutate } = useSWR(
    () => checkoutId,
    async (id) =>
      sdk
        .checkout({ id })
        .then(({ node }) => node as CheckoutBaseFragmentFragment)
  );

  useEffect(() => {
    if (
      checkout === null ||
      checkout?.lineItems?.edges?.some(({ node }) => !node.variant) ||
      checkout?.completedAt
    ) {
      setCheckoutId("");
    }
  }, [checkout]);

  const addItemToCart = async (lineItem: CheckoutLineItemInput) => {
    try {
      setUpdatingCart(true);
      if (!checkoutId) {
        const { checkoutCreate } = await sdk.checkoutCreate({
          input: { lineItems: [lineItem] },
        });
        setCheckoutId(checkoutCreate.checkout.id);
        mutate(checkoutCreate.checkout, false);
      } else {
        const lineItems: CheckoutLineItemInput[] = checkout?.lineItems?.edges?.reduce(
          (acc, { node }) => {
            if (lineItem.variantId === node.variant.id)
              return [
                {
                  variantId: lineItem.variantId,
                  quantity: node.quantity + lineItem.quantity,
                },
                ...acc.slice(1),
              ];
            return [
              ...acc,
              { variantId: node.variant.id, quantity: node.quantity },
            ];
          },
          [lineItem]
        );

        const { checkoutLineItemsReplace } = await sdk.checkoutLineItemsReplace(
          {
            checkoutId,
            lineItems,
          }
        );
        mutate(checkoutLineItemsReplace.checkout, false);
      }
    } catch (e) {
      console.warn(e);
      setCheckoutId("");
    } finally {
      setUpdatingCart(false);
    }
  };

  const adjustLineItemQuantity = async (lineItem: CheckoutLineItemInput) => {
    try {
      setUpdatingCart(true);
      const lineItems = checkout.lineItems.edges.reduce((acc, { node }) => {
        if (lineItem.variantId === node.variant.id) {
          if (!lineItem.quantity) return acc;
          return acc.concat({
            variantId: node.variant.id,
            quantity: lineItem.quantity,
          });
        }
        return acc.concat({
          variantId: node.variant.id,
          quantity: node.quantity,
        });
      }, [] as CheckoutLineItemInput[]);

      const { checkoutLineItemsReplace } = await sdk.checkoutLineItemsReplace({
        checkoutId,
        lineItems,
      });
      mutate(checkoutLineItemsReplace.checkout, false);
    } catch (e) {
    } finally {
      setUpdatingCart(false);
    }
  };

  const removeLineItem = async ({ variantId }: { variantId: string }) => {
    const lineItems: CheckoutLineItemInput[] = checkout.lineItems.edges
      ?.filter(({ node }) => node.variant.id !== variantId)
      .map(({ node }) => ({
        variantId: node.variant.id,
        quantity: node.quantity,
      }));

    const { checkoutLineItemsReplace } = await sdk.checkoutLineItemsReplace({
      checkoutId,
      lineItems,
    });
    mutate(checkoutLineItemsReplace.checkout, false);
  };

  return (
    <ShopifyContext.Provider
      value={{
        addItemToCart,
        removeLineItem,
        adjustLineItemQuantity,
        updatingCart,
        checkout,
      }}
    >
      {children}
    </ShopifyContext.Provider>
  );
};
