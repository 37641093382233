import { MouseEvent } from "react";

export const withPropagation = (
  e: MouseEvent<HTMLElement>,
  func: () => void
): void => {
  e?.stopPropagation();

  return func();
};
