import { EventQuery, MembershipQuery } from "~graphql/sdk";

type Promotion =
  | EventQuery["event"]["multiBuyPromotions"][number]
  | MembershipQuery["membership"]["multiBuyPromotions"][number];

export const useSeatedMultibuy = (promotions: Promotion[], seats: any[]) => {
  const activePromotions = promotions
    ?.filter((promotion) => promotion.enabled)
    ?.reduce((acc, curr) => {
      const getType =
        ("getTicketType" in curr && curr?.getTicketType) ||
        ("getMembershipType" in curr && curr?.getMembershipType);
      const buyType =
        ("buyTicketType" in curr && curr?.buyTicketType) ||
        ("buyMembershipType" in curr && curr?.buyMembershipType);

      if (buyType && getType) {
        const buySeats = seats?.filter(
          ({ ticketType, membershipType }) =>
            (ticketType?.value || ticketType?.id || membershipType?.id) ===
            buyType?.id
        );

        const getSeats = seats?.filter(
          ({ ticketType, membershipType }) =>
            (ticketType?.value || ticketType?.id || membershipType?.id) ===
            getType.id
        );

        const buyTotal = buySeats?.length;
        const buySeatQty = Math.floor(buyTotal / curr.buyQuantity);

        const getTotal = getSeats?.length;

        const giftedQty =
          buySeatQty * curr.getQuantity <= getTotal
            ? buySeatQty * curr.getQuantity
            : getTotal;

        if (
          getSeats &&
          buySeats &&
          buySeats.length >= curr.buyQuantity &&
          (getSeats.length >= curr.getQuantity || curr.price === 0)
        ) {
          const activePromo = [
            ...buySeats.map((buy) => ({
              promotion: curr,
              ...buy,
              type: "buying",
            })),
            // sort by price, so we can add discount text to the discount seats
            ...getSeats
              ?.sort((a, b) => a.price - b.price)
              .slice(0, giftedQty)
              .map((get) => ({
                promotion: curr,
                ...get,
                type: "getting",
              })),
          ];

          return [
            ...acc,
            {
              ...curr,
              seats: activePromo,
              isActive: getSeats?.length > 0,
            },
          ];
        }
      }

      return [
        ...acc,
        {
          ...curr,
          isActive: false,
        },
      ];
    }, []);

  /** @note we'll return the first promotion that is active **/
  return {
    activePromotions:
      activePromotions?.find((promo) => promo?.isActive)?.seats ?? [],
  };
};
