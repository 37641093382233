import { parseISO } from "date-fns";
import { format, toZonedTime } from "date-fns-tz";
import { enNZ } from "date-fns/locale";
import {
  EventDateRangeOptions,
  FormatDateOptions,
  FormatType,
  ZonedDate,
} from "@flicket/utils";
import { i18n } from "~lib/i18n";

/**
 * Formats the given date based on the en-NZ locale and timezone into a human readable formatted date string.
 *
 * Uses format and toDate from 'date-fns-tz' internally
 *
 * @param date - ISO string representing UTC time
 * @param dateFormat - defaults to "dd/MM/yyyy"
 *
 * @returns A string representing the formatted date in the Organization timezone
 *
 * @remarks
 * This functions works based on a **fixed time zone**
 * This is done because events should always be displayed in the time zone where the events takes place
 */
export const formatDate = (date: string | Date, dateFormat = "dd/MM/yyyy") => {
  const timeZone = i18n.timezone;

  return (
    date &&
    format(
      toZonedTime(typeof date === "string" ? parseISO(date) : date, timeZone),
      dateFormat,
      {
        locale: enNZ,
        timeZone,
      }
    )
  );
};

export function formatEventDateRange(
  startDate: string,
  endDate: string,
  options: EventDateRangeOptions
) {
  return ZonedDate.formatEventRange(startDate, endDate, {
    format: "basicShort",
    ...options,
    timeZone: options.timeZone ?? i18n.timezone,
    locale: options.locale ?? i18n.locale,
  });
}

export function formatEventDate(
  date: string,
  type: FormatType = "date",
  options: FormatDateOptions
) {
  return ZonedDate.format(date, type, {
    ...options,
    timeZone: options.timeZone ?? i18n.timezone,
    locale: options.locale ?? i18n.locale,
  });
}

export function formatOrgDate(
  date: string,
  type: FormatType = "date",
  options?: Partial<FormatDateOptions>
) {
  return ZonedDate.format(date, type, {
    ...options,
    timeZone: i18n.timezone,
    locale: i18n.locale,
  });
}