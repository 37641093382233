export const marketingUrl =
  process.env.MARKETING_URL || "https://flicket.co.nz";

export function marketingOptInCopy(
  organization: { name: string },
  type: "email" | "sms" | "full"
): string {
  const name = organization.name ?? "Flicket";
  if (type === "full") {
    return `I'd like to receive updates on ${name} news, events, and offers via email and text messages`;
  }
  const labelForType = type === "email" ? "email" : "text message";
  return `${name} can send me ${labelForType} marketing.`;
}
