import { registerInstrumentations } from "@opentelemetry/instrumentation";
import {
  Resource,
  browserDetector,
  detectResourcesSync,
} from "@opentelemetry/resources";
import {
  ConsoleSpanExporter,
  SimpleSpanProcessor,
  BatchSpanProcessor,
} from "@opentelemetry/sdk-trace-base";
import { WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import { SemanticResourceAttributes } from "@opentelemetry/semantic-conventions";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import { apiUrl } from "~config";

export const SERVICE_NAME = "flicket-customer-portal";
export const TRACER_NAME = `${SERVICE_NAME}-tracer`;

const FrontendTracer = async () => {
  const { ZoneContextManager } = await import("@opentelemetry/context-zone");

  const contextManager = new ZoneContextManager();

  let resource = new Resource({
    [SemanticResourceAttributes.SERVICE_NAME]: SERVICE_NAME,
  });
  const detectedResources = detectResourcesSync({
    detectors: [browserDetector],
  });

  resource = resource.merge(detectedResources);

  const provider = new WebTracerProvider({
    resource,
  });

  if (process.env.NODE_ENV === "development") {
    provider.addSpanProcessor(
      new SimpleSpanProcessor(new ConsoleSpanExporter())
    );
  }

  // Exporters
  // Export directly to our nestjs backend
  provider.addSpanProcessor(
    new BatchSpanProcessor(
      new OTLPTraceExporter({
        url: `${apiUrl}/telemetry/v1/traces`,
      })
    )
  );

  // Export via a Collector which will forward requests to our nestjs backend
  // This is the preferred method for production since the collector will batch requests
  // provider.addSpanProcessor(new BatchSpanProcessor(new OTLPTraceExporter()));

  provider.register({
    contextManager,
  });

  registerInstrumentations({
    tracerProvider: provider,
  });
};

export default FrontendTracer;
